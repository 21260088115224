import React from "react";
import oxala from "../components/images/oxala.jpeg";
import iemanja from "../components/images/iemanja.jpeg";
import ogum from "../components/images/ogum.jpeg";
import oxossi from "../components/images/oxossi.jpeg";
import oxum from "../components/images/oxum.jpeg";
import xango from "../components/images/xango.jpeg";

export const previsions = [
  {
    year: "2023",
    title: "Oxalá",
    img: oxala,
    text: (
      <p>
        VAMOS ENTRAR EM 2.023, que começa num DOMINGO, ANO QUE SERÁ GOVERNADO
        POR OXALÁ: Regente da Paz, Sorriso e Tranquilidade. EM 2023, O MUNDO
        SERÁ GOVERNADO PELA ÉGIDE DE OXALÁ LUFÃ. Recém agora que chegamos a
        conclusão que será o velho Oxalá (OXÁLUFÃ). Pois estávamos entre o Lufãn
        e o Guiãn, só tínhamos a certeza de que o Urumylaya, não seria pois em
        2.017 foi ele que mandou. O Oxaguiã é Oxalá Menino, graça, beleza,
        divertimento e o Oxalá Lufã (OXALUFÃ) O SÁBIO, E QUE ALÉM DA PAZ, para
        acabar com a guerra da Rússia X Ucrânia, extremamente perigosa para toda
        a humanidade, ENTENDE DE SAÚDE, foi necessário, para ajudar que o vírus
        COVID, seja realmente extinto. CARACTERÍSTICAS DOS FILHOS DE OXALUFÃ.
        Líder, benevolente, rabugento, responsável, inteligentes,
        participativos, planejadores, exigentes até demais, para os
        planejamentos, são exigentes para consigo mesmo. NEGATIVAS: Não são bons
        executores, em virtude de serem extremamente enérgicos consigo mesmo.
        Tem dificuldades para ouvirem opinião alheia, Não pedem ajuda aos
        outros. Meticulosos, reacionários, exclusivistas, não gostam de serem
        mandados e quando tem poderes de mando nas mãos, são autoritários e
        fazem de tudo para manter o poder de mando nas mão. Gostam de
        centralizar tudo ao seu redor, o que lhe faz mal para a sua saúde
        mental, em virtude de sua intransigência. ACONTECIMENTOS MARCANTES DO
        ANO – Como OXALÁ, não rege e nem entende de dinheiro, o ano será um
        tanto difícil, obrigando a todos terem cautelas nos gastos, aprendendo a
        economizar, evitando esbanjamentos. Haverá uma preocupação mundial com a
        geração de empregos, pelas dificuldades imensas financeiras. No amor
        HAVERÁ mais cautela em ambos os lados, ou seja, será com mais razão e
        menos paixão. A maldade, a crueldade, a mentira e o desamor não terão
        guarida neste ano de OXALÁ LUFÃ. O Espiritualismo, principalmente a
        Cultura Africana, que alguns ainda olham com desconfianças, em virtude
        dos maus espiritualistas, será VALORIZADA E CADA VEZ MAIS RECONHECIDA E
        RESPEITADA, NO MUNDO INTEIRO. A inflação por GANÂNCIA, SOFRERÁ UMA BAQUE
        MUITO GRANDE PELAS AUTORIDADES. Na agricultura, mais uma vez, a soja, o
        trigo e o milho , serão destaques mundiais na produção agrícola. Na
        pecuária: o gado e a suinocultura, serão valorizados. Comércio com água,
        artigos domésticos, construção civil, construção de estradas, imóveis,
        mesmo com a diminuição da margem de lucros, terá sucesso. A Classe
        política, será severamente policiada. Igual o ano de 2012, a última vez
        que o Lufã regeu. DOENÇAS DO ANO: MULHERES: Extremas dificuldades para
        engravidar, ciclos menstruais desajustados, ter o máximo cuidados com
        útero e ovários. HOMENS: Extremos cuidados com o estômago, intestinos,
        funções digestivas em geral, incontinência urinária. Problemas com rins
        e próstata. Haverá um avanço na medicina, sobre a doença da próstata,.
        Ano de Paz, compreensão e tranquilidade.
      </p>
    ),
  },
  {
    year: "2022",
    title: "Oxum Maré",
    img: oxum,
    text: (
      <p>
        Meus presados irmãos, A GRANDE RODA DA VIDA VAI DAR MAIS UMA VOLTA. Mais
        um ano que chega ao fim. O ano de 2021 ANO DE YEMANJÁ SOBAH. O onde
        previmos que o amor, fertilidade e fartura Prevaleceria, sob sua
        regência. A fertilidade foi o que mais fortemente aconteceu, como
        exemplo registramos aquela mulher na África (Halima Cisse) que ganhou
        nove(9) filhos (nônuplos) de uma vez só, e no Brasil, aquela que foi
        para ganhar um filho, nasceu três(3), isso sem contar que centenas de
        mulheres foram surpreendidas com a gravidez. Mesmo com o evento do COVID
        19, milhões de pessoas sem trabalhar, a economia no mundo todo, se
        manteve instável, pois os governantes esperavam coisa muito pior, terra
        arrasada, o que não aconteceu. Vamos entrar agora em 2022, cujo ano
        começará num sábado, terá a regência de OXUM, égide de OXUM MARÉ. Orixá
        considerado Andrógino. Meio Homem, meio Mulher. ACONTECIMENTOS MARCANTES
        NO ANO: Notem a importância e o respeito que estão conseguindo os
        homossexuais em todos os setores da sociedade. Na televisão, no esporte,
        na política, em todos os seguimentos da humanidade, está acabando a
        discriminação cruel que existia, os homossexuais não terão mais que
        passar por constrangimentos estúpidos, muitas vezes maldades incríveis.
        Isso começa acabar neste ano que se inicia 2.022 DE UMA MANEIRA CORRETA,
        realidade pura. OXUM MARÉ, DEUSA do AMOR, BONDADE e OURO. Rainha das
        Cachoeiras, Deusa dos rios, riachos, lagos e lagoas. Simboliza a graça e
        a beleza. Com poderes extremos sobre a água doce. Sem ela, não haveria
        vida na terra. Símbolo da Oxum Maré: ARCO IRIS E CORAÇÃO. As filhas de
        OXUM, são vaidosas, algumas ao extremo, como as Pandás, pois “limpam
        suas joias, antes de limparem seus filhos” Temperamento forte.
        Guerreira. Poderosa. Em virtude da ambiguidade, meio homem meio mulher,
        encontram dificuldades no campo sentimental. Sua aparência graciosa e
        sedutora esconde uma forte vontade de ascensão social, muitas vezes sem
        medir consequências. Não gostam de ter muitos filhos, porém gostam de
        cuidar e zelar pelos filhos dos outros. Assim como foi o ano de 2021, o
        ano 2022, também será ano da mulher, mesmo que neste ano será dividido
        entre homem e mulher. O amor verdadeiro prevalecerá entre os casais.
        Muito raro, as separações de casais, neste ano da Maré. Os que ainda
        estão sozinhos, terão a oportunidade neste ano de achar sua cara metade
        e ser feliz MESMO, pois será ano do amor verdadeiro. Ano de progresso
        para a humanidade inteira. Deverá ser um ano calmo, apesar de ano
        político que felizmente veio cair num ano da Oxum, pois seria
        extremamente nervoso. A fecundidade continuará, não tão destacada como
        neste ano que finda, mas as inseminações estarão protegidas para o
        sucesso. As adoções de crianças desamparadas avançarão, também estarão
        sob proteção de Oxum Maré. A espiritualidade mais uma vez terá um avanço
        considerável. O espiritualismo ganhará milhares de novos adeptos. A
        reencarnação terá um avanço no conceito da humanidade, uma aceitação
        maior por aqueles que não acreditam. NA ÁREA DE COMÉRCIO: No ramo de
        imóveis, artesanatos, jóias, salão de beleza, cirurgias plásticas,
        alimentação e artigos femininos, serão os mais bem sucedidos. A beleza e
        a Vaidade tanto de mulheres como homens estarão em alta. Mulheres e
        também homens que nunca entraram num salão de beleza, procurarão estes
        profissionais da beleza. Na agropecuária haverá surpresas agradáveis.
        Avicultura e Suinocultura. Soja e trigo, apesar de passar por um período
        longo de falta de chuva. Os poderosos continuarão diminuindo suas
        margens exorbitantes de lucros o que irá gerar mais empregos. A
        valorização da água doce atingirá seu clímax no mundo inteiro com
        atenção no desperdício e terão que economizar água ao extremo. COISAS
        RUÍNS ANO 2022: As doenças do ano para mulheres, estarão relacionadas
        com o baixo ventre, útero, ovários e trompas. Para homens: Próstata e
        intestino. Cuidados especiais para ambos os sexos, com nervos,
        ansiedade, sistema respiratório e cordas vocais. Calor extremamente
        quente, atingindo níveis quase insuportáveis. Para tentar evitar estas
        coisas ruins e poder contar com as coisas boas, que irão acontecer no
        próximo ano, façam o possível de AGRADAR a OXUM com as seguintes ações:
        No dia 31.12.2021 perto da meia noite, procure vestir uma camisa, uma
        blusa, uma calça, até mesmo uma roupa intima, na cor amarela, até mesmo
        uma fita na cor amarela, ou seja, alguma coisa na cromoterapia da Oxum,
        que é o AMARELO. Se possível, uns 15 minutos antes, acenda uma vela na
        cor amarela e no pino da HORA GRANDE (MEIA NOITE) DO DIA 31.12.2021, com
        fé e muita devoção e confiança faça seus pedidos. Os ORIXÁS NÃO SÃO
        DIVINOS, são DIVINIZADOS, por isso que é necessário AGRADÁ-LOS com
        oferendas, mesmo que singela como uma simples vela na cor do orixá
        regente. CREIA, faça com fé, amor, respeito e CONFIANÇA. Tenham um BOM
        ANO DA OXUM MARÉ (2022). Que OXALÁ nos abençoe a todos!
      </p>
    ),
  },
  {
    year: "2021",
    title: "Yemanjá",
    img: iemanja,
    text: (
      <p>
        ANO DA HARMONIA, DA FERTILIDADE E DA FARTURA O ano de 2.021, como se
        inicia numa sexta-feira, e pela Tábua Cronológica dos Orixás da Nação de
        Cambinda, que consideramos a mais perfeita, será regido por YEMANJÁ,
        (Yê-Yê-omo-ejá – Mãe dos Peixes ou Mãe-Filhos-Peixes), Rainha das Águas
        Salgadas, Mãe de Todas as Mães – Símbolo da Fertilidade, exerce sua
        regência sobre: Harmonia e Fartura. Hoje, na Umbanda e em algumas Casas
        de Candomblé, é o único Orixá que tem sua imagem própria nos Altares,
        sem sincretismo (antigamente sincretizava com Nossa Senhora dos
        Navegantes): Uma bela mulher, saindo das águas do Mar, com vestimenta em
        Azul Claro, com colo nu, espargindo estrelas de ambas as mãos. Na África
        e em Cuba ela é representada nas imagens com o aspecto de uma matrona,
        de seios volumosos, símbolo de maternidade fecunda. As filhas de Yemanjá
        são voluntariosas, fortes, rigorosas, protetoras, altivas e algumas
        vezes impetuosas e arrogantes; tem sentido da hierarquia, fazem-se
        respeitar e são justas, mas formais; põem à prova as amizades que lhe
        são devotadas, custam muito a perdoar uma ofensa, se perdoam não a
        esquecem jamais. Preocupam-se com os outros, são maternais e sérias. Sem
        possuírem a vaidade da Oxum, gostam do luxo, das fazendas azuis e
        vistosas, das joias caras. Elas têm tendência à vida suntuosa mesmo se
        as possibilidades do cotidiano não lhes permitem tal fausto.
        Características Físicas das Filhas de Yemanjá: Estatura de media para
        baixa em sua grande maioria. Rosto cheio, braços ligeiramente finos, pés
        pequenos, nariz um tanto arrebitado, gostam de cabelos longos. Seios
        robustos. Dia da Semana: Sexta-Feira; Cor: Azul Claro (Celeste) e
        Branco. Saudação: Omy-ô! Odôcy-Ába! Odô-Yá! Instrumento Símbolo: Abano
        (uma espécie de leque) de metal branco: Abebé; Símbolo Cabalístico: Três
        Ondas do Mar. Defeitos das filhas de Yemanjá: Apesar de serem maternais,
        com zelo admirável na defesa e cuidados com o esposo e seus filhos,
        muitas vezes se mostram por demais arrogantes. No trato do lar e de suas
        coisas se tornam chatas porque querem as “coisas no seu devido lugar”
        chegando a beira do fanatismo se alguém tira alguma coisa do lugar onde
        ela colocou. Não raro os momentos em que demonstra falta de imaginação,
        principalmente quando repreendida, torna-se frágil e cai em prantos. Tem
        uma sutileza irritante para criticar os erros dos outros. Irritante,
        pois logo em seguida se propõe em ajudar, mesmo não confiando nas
        pessoas. Como mãe e pelo excesso de zelo para com os filhos, torna-se
        possessiva, mantendo seus filhos sob um domínio, muitas vezes cruel,
        querendo dominar a personalidade dos filhos, querendo que estes ajam de
        acordo como elas querem e pensam, quando assim não acontece, se tornam
        más, cruéis, ao ponto de perde-los. E quando isso acontece, se
        transformam em vítimas, fazendo chantagens emocionais até conseguir a
        volta deste ou destes filhos. Cito isso porque nossos irmãos ainda
        deverão ouvir pela mídia central, que será outro Orixá, o que
        evidentemente não será o correto. Lembro a todos que coloco os defeitos
        para que as filhas de Yemanjá se policiem. O ano terá acontecimentos
        marcantes, como: Amor, Fertilidade, harmonia, fartura. A energia do
        romantismo estará no ar. Aquelas pessoas que ainda estão sós, que ainda
        não encontraram o seu par ideal, encontrarão neste ano de 2.021.
        Exatamente a pessoa que compartilhará sentimentos, sonhos. Os
        relacionamentos em geral serão mais harmoniosos. O número de casamentos
        deverá aumentar consideravelmente. Mulheres que têm enfrentado
        dificuldades para engravidar terão neste ano a grande oportunidade de
        gerarem filhos, onde algumas mulheres com até 52 anos de idade poderão
        engravidar. Haverá mais estabilidade emocional e financeira, pois estes
        ideais irão acompanhar o ser humano. A produção de alimentos no mundo
        todo estará em franca ascensão. O espiritualismo, a moda, tratamentos
        estéticos, atividades artísticas, comércio de imóveis para casa própria,
        processos legais, todos os precatórios, federais, estaduais e municipais
        serão pagos, pois Yemanjá também é Justiça e Equilíbrio. Todos estes
        segmentos estarão em franca expansão. O Direito será uma profissão muito
        prestigiada. A cura e até mesmo a erradicação do câncer de mama (o que
        aconteceu neste ano que finda, ano de Oxóssi) a medicina terá um avanço
        gigantesco e surpreendente. Os conflitos mundiais terão um aliado enorme
        que é a diplomacia, pois esta estará em alta. Acontecimentos negativos
        para o ano: A humanidade mais uma vez aprenderá a respeitar a natureza,
        pois tudo indica que o Mar poderá se revoltar novamente e teremos então
        outro tsunami. Muitos acidentes nas águas, doce e salgada. E como ela só
        protege nas águas, acidentes enormes em terra acontecerão, pela
        imprudência e falta de bom senso dos homens. Muitos “mistérios” que
        ainda habitam as profundezas do mar surgirão à tona, o que nos dará mais
        certeza de que será do mar que os humanos terão, um dia, sua maior fonte
        de alimentos. Doenças mais acentuadas no ano de Yemanjá: Artrite,
        artrose, osteoporose, próstata, pulmão, varizes e trombose. Para agradar
        Yemanjá, no dia 31 de dezembro/20, vista uma roupa na cor azul claro
        e/ou branca. Pode ser roupa íntima, ou pelo menos porte alguma coisa,
        uma fitinha, por exemplo, na cor azul claro. Para os mais arrojados e
        com fé, acenda uma vela azul claro, pode ser de 7 dias, e faça com que
        esta vela esteja acesa, a Zero Hora do dia 1º de janeiro de 2.021. Faça
        seus pedidos. Lembrem-se os Orixás não são Divinos, são Divinizados e
        por isso que eles precisam do agrado para atender os seus pedidos e além
        de sua fé, sua CONFIANÇA!
      </p>
    ),
  },
  {
    year: "2020",
    title: "Oxossi e Yansã",
    img: oxossi,
    text: (
      <p>
        Em virtude do ano começar numa quarta-feira, que pela Tábua Cronológica
        dos Orixás da Nação de Cambinda, quarta feira é regida pelos orixás
        Oxóssi e Yansã, o que nos dá o fundamento da regência do ano de 2020
        será de OXOSSI E YANSÃ. Mesmo tendo Yansã no segundo plano, a regência
        maior será a de Oxóssi: CAÇADOR DE ALMAS, SENHOR DA FLORESTA, REI DAS
        MATAS, DONO DA TERAPÊUTICA DO ESPAÇO(SAÚDE). Os filhos de Oxóssi são
        reconhecidos na numerologia pela soma de apenas dois números 26 e 28.
        Isso nos dá a informação porque tem escritores que registram que os
        filhos de Oxóssi estão em extinção, pois apenas duas somas na
        numerologia indicam quem é filho de Oxossi. São desconfiados, fiéis aos
        seus amigos, cuidadosos em tudo, preocupa-se com o dia de amanhã, Bom
        amigo, calmo e explosivo. Quando calmo chega a irritar e quando
        explosivo vai as raias da ignorância. Os filhos de Oxossi traz um
        estigma preocupante, pois parece que nasceram para serem eternamente
        solteiros em virtude de passarem uma vida inteira em busca da mulher
        ideal que só existe na cabeça deles. Por isso que ao casar com qualquer
        mulher tem dificuldades imensas em fazê-las feliz em virtude deste
        estigma de procurar a mulher ideal. A única mulher que ainda aceita o
        jeito estranho de amar dos filhos de Oxossi são as filhas de Yansã, que
        mesmo assim chega um momento da vida de casada que se aborrecem tornando
        o relacionamento como “cansada” e até insuportável e o filho de Oxóssi
        passa a ser um homem triste o que geralmente acaba em separação.
        Característica dos filhos de Oxóssi: No campo de amizade, é o melhor
        amigo que você pode ter. É capaz de tirar a própria camisa para ajudar
        um amigo. Lábio inferior geralmente mais grosso que o superior. Pele
        delicada e morena. Individualista. “Caçador de si mesmo”. DEFEITOS: São
        um tanto fantasiosos com os problemas. Um dos defeitos é a “acomodação”
        ou seja, não tem princípios de ganância, mesmo não sendo defeito em
        alguns momentos da vida. Dificilmente são fiéis as suas parceiras. Estão
        sujeitos a paixões “relâmpagos” á “primeira vista”. Geralmente casam
        muitas vezes para não ficarem na “solidão”. Galanteadores. Amáveis.
        Ligeiramente preguiçosos. Muitas vezes “aumenta” ou “inventa” coisas,
        nada que venha prejudicar outrem. Tem imensas dificuldades de usar as
        palavras “meu amor”, “minha querida”, enfim dialogar com a companheira,
        mesmo amando-a. Porém quando perde a pessoa amada, entra em parafuso. Ou
        seja, só valoriza a companheira quando a perde. ACONTECIMENTOS MARCANTES
        DO ANO: Será o ano do avanço da medicina em diversos casos de doenças,
        mesmo que sabemos que no ano de Oxossi, além do avanço da medicina em
        casos de doenças, aparece sempre alguma doença séria e geralmente
        perigosa. A medicina avança na busca da cura do Alzheimer e de
        Parkinson. Início de estudos para achar uma forma de amenizar os efeitos
        colaterais e muitas vezes danosos da quimioterapia. Mais um grande
        avanço do espiritualismo. A Reencarnação ganhará mais adeptos. O
        reconhecimento do homossexualismo, praticamente no mundo todo.
        Atividades valorizadas: As relacionadas com ervas, plantas, madeiras e
        suplementos alimentares. Ainda; pesca, Veterinária, Jornalismo,
        Marketing, Turismo. Ecologia e Representante Comercial. No governo do
        Brasil o presidente cuja numerologia é 28 (Oxossi) terá um excelente
        oportunidade de ter um ano cheio de realizações e reconhecimento. COISAS
        RUINS DO ANO: Doenças: Estômago e regiões das pernas, joelhos e pés.
        Continua o aquecimento global se tornando mais perigoso. Vendavais,
        ciclones, tufões e terremotos, em virtude da regência, mesmo que em
        segundo plano, de Yansã. Chuvas torrenciais, mesmo que passageiras, mas
        que provocará alagamentos. Se a Yansã não estivesse em segundo plano,
        seria um ano de pouca chuva, provocando até seca em alguns lugares do
        planeta. Os aviões enfrentarão turbulências acima da média, onde
        provocará acidentes aéreos. Surgirá uma doença perigosíssima que estará
        entre três males: Transmutação de GRIPE, SARAMPO e/ou PSORIÁSE, numa
        delas ou nas três. OXÓSSI: Dia da Semana: Quarta Feira - Cor: Verde –
        Sincretismo: São Sebastião – Símbolos Cabalísticos: Arco e Flecha –
        Penas – Dentes de Animais Selvagens – Saudação: Ókê! – Ókê Arô!YANSÃ:
        Dia da Semana: Quarta-Feira – Cor: Azulão (cor do céu numa tempestade) –
        Sincretismo: Santa Bárbara – Símbolos Cabalísticos: Raios – Abanos
        (Leques) – Espada Gamada – Saudação: Eparrêy! – Epayêyo!{" "}
      </p>
    ),
  },
  {
    year: "2019",
    title: "Xangô",
    img: xango,
    text: (
      <p>
        O ANO DE 2.019 será regido por XANGÔ, sendo apenas 6(seis) anos depois
        ele volta a reger os destinos da humanidade, sendo que desta vez será
        XANGÔ AGANJÚ. Orixá este que tem sob sua égide a capacidade de unificar
        as pessoas. Orixá dos raios, terremotos, ventos, trovões e fogo. Sendo
        atrevido, violento e justiceiro. Xangô Aganju costuma castigar os
        ladrões e mentirosos. Quando alguém é atingido por um raio, é
        considerado uma punição desse senhor da justiça. O dia comemorado deste
        orixá é pelo sincretismo, dia 29 de junho, dia de São Pedro. Este Xangô
        é considerado o orixá da sabedoria, gerando poder sobre a política e
        demais ciências. Os filhos deste orixá são pessoas enérgicas,
        conscientes e voluntárias. Tendo o poder de liderança, se dá muito bem,
        pois tem boa comunicação e inteligência, tornando-se importantes
        políticos e advogados. Seus filhos são justos, sendo até uma obsessão, o
        que faz seu filhos um sofredor, pois seus parâmetros de justiça e seu
        julgamento, muitas vezes não são os da Justiça Divina, quase sempre
        diferente do nosso, muito terra. Apresentam um tipo firme, enérgico,
        seguro e absolutamente austero, tem comportamento medido. As vezes
        considerados chatos. É incapaz de dar um passo maior que a perna e todas
        as suas atitudes e resoluções baseiam-se na segurança e chão firme que
        gosta de pisar. É tímido no contato, mas assume facilmente o poder de
        mando. CARACTERISTICAS DE XANGÔ: Dia da semana: TERÇA-FEIRA. Cor: Todas
        as matizes da cor Marrom. Sincretismo: São Pedro, para o Xangô Aganju.
        Símbolos: Machado de duas Lâminas (oxé, oxó ou osé) e a Chave.
        ACONTECIMENTOS MARCANTES DO ANO: Como fundamental a Justiça Perfeita.
        Desde 15 de outubro, o Aganjú já vem entrando. O governo brasileiro terá
        muitas dificuldades para gerenciar nosso pais, pois este orixá não
        suportará, mentiras, roubo, corrupções, estelionatos, qualquer maldade
        no sentido de lesar alguém, será implacavelmente castigada. Ano da
        persistência e da disciplina. Haverá um movimento mundial no sentido da
        moralidade. Cuidados com as precipitações, agressividades e violências
        descabidas. As emoções e sensualidade estarão fortalecidas neste ano.
        Extremos cuidados com INCÊNDIO, como no ano passado, este orixá também
        rege o fogo. Voltará a polêmica sobre a liberação das drogas que
        envolverá a sociedade, Religião e Judiciário. Terremotos, muitos raios,
        VENTOS FORTES, vulcões adormecidos irão acordar. ATIVIDADES FAVORECIDAS:
        Magistratura, Promotoria, Advocacia, Matemática, Política e
        Fiscalização. Construção Civil e estradas. Móveis e utensílios.
        Prudência em investir em alguma coisa que não traga benefícios em curto
        prazo. Exercite a paciência e o respeito pelos entes queridos. DOENÇAS
        DO ANO: As relacionadas com o coração. Ansiedade. Hipertensão e
        Impotência. Respeitar os limites para descansar, pois estaremos
        vulneráveis a derrame cerebral e infarto do miocárdio.
      </p>
    ),
  },
  {
    year: "2018",
    title: "Bará",
    img: "",
    text: (
      <p>
        PARA O ANO 2018, a humanidade será regida por BARÁ, Comandante Supremo
        da Linha de Exú e Pombas-Giras. QUEM É e o QUE É O BARÁ? 2 0 1 8 – ANO
        DO BARÁ. O ano de 2.018 começará numa segunda-feira, dia da semana
        regido por Bará e toda a linha de esquerda. Portanto o ano será regido
        por Bará, regente maior da Linha de Exús e Pomba-Giras. BARÁ não é orixá
        e sim um Ébórá, alguns confundem com Orixá. Que entende muito de
        dinheiro, paixão e fogo. Apesar de ter uma força gigantesca, tendo até
        algumas pessoas que se dizem ser filhos de BARÀ, o que aceitamos, mas
        não acreditamos, pois esta entidade não é um Orixá Maior, portanto não
        pode ser Pai de Cabeça de ninguém. Pode ser sim considerado como pai por
        afinidade, pela fé, por respeito e até profunda admiração por esse
        Ébórá. O que é um Ébórá? É uma palavra, um termo iorubá, que significa
        um homem forte, aquele que é notável, um mito poderoso, exatamente
        nestas definições que reside o fato de muitos considerarem o Bará como
        orixá. Em virtude da importância ao grupo social que faziam parte quando
        vivos obtiveram esta visão e mitos poderosos quando mortos, sendo
        cultuados pelos seus próprios povos como ancestrais importantes. Com
        isso em cada evocação, foram confundidos com os orixás maiores, por
        serem seres divinizados elementares da natureza, estes sim não tiveram
        vida terrena. Os ébórás e os orixás fazem parte das figuras centrais nos
        cultos, iorubano e afro-brasileiro e muito cultuado na maioria dos
        terreiros de Umbanda. Após esta explicação vamos tratar a partir de
        agora o Bará como orixá, até para maior compreensão de todos. É o Orixá
        mais humano de todos, sua semelhança com nós, seres humanos, é
        impressionante. A ligação do Bará com o ser humano é tão intensa que é o
        mensageiro entre os homens e os Orixás Maiores. Dono das Encruzilhadas,
        das estradas, das ruas, enfim de todos os caminhos. Por isso sua
        saudação é sempre a primeira na abertura de todos os trabalhos. Alguns
        terreiros tem os seus Exús Guardiões, outros tem o Pantera Negra (que
        não é homem ,nem mulher, que não é o bem nem o mal, que não é Deus e nem
        o Diabo, é sim um grande Ébóra), registro isso para que saibam então que
        não é necessariamente saudar ou cantar para Bará explicitamente no
        inicios dos trabalhos, e sim para qualquer Exu que a Casa tenha uma
        maior consideração, fé e respeito. ACONTECIMENTOS MARCANTES NO ANO:
        POSITIVOS: Tudo que for com referência a terra, dará certo. Agricultura,
        Construção de Estradas, Construção Civil, Transportes Rodoviários. Será
        um ano de dinheiro. A economia mundial dará um salto considerável. O
        Espiritualismo continuará seu crescimento e a Cultura Africana mais
        reconhecida e respeitada. NEGATIVOS: Acidentes aéreos, pois o Bará puxa
        tudo para terra. Incêndios. Será um ano marcado por VINGANÇA: Bará é
        conhecido como Orixá Cego, que não perdoa. Ele prima e favorece o
        sentimento de Vingança, contra todo aquele que fizer o mal a quem quer
        que seja. O ser humano que for prejudicado por alguém, de alguma
        maneira, a primeira reação que virá na sua cabeça é a VINGANÇA. No campo
        dos relacionamentos haverá pouco amor e avassaladora PAIXÃO. Ano de
        muitas doenças, pois este orixá não rege nada na saúde. A doença
        principal será relacionada com a cabeça, como derrames cerebrais,
        Alzheimer e tumores cerebrais. Sinopse: BARÁ – Dia da Semana:
        Segunda-Feira – Sincretismo: Santo Antônio Cor: Vermelho e Preto –
        Saudação: ELEG – ELEGBÁ - ELEGBÁRA
      </p>
    ),
  },
  {
    year: "2017",
    title: "Oxalá",
    img: oxala,
    text: (
      <p>
        ANO DE 2017 – Ano de Oxalá, que vem na vibração de Oxalá Urumilaia ou
        Orunmilá, o da adivinhação e tranquilidade. O ano se inicia num domingo,
        e por isso terá a regência deste Orixá. OXALÁ é regente da Paz, Sorriso
        e Tranquilidade. Considerado o maior de todos os Orixás. Sábio e
        Inteligente. Nem tão curvado como as outras vibrações de Oxalá. Também é
        uma figura bondosa, carrega um cajado em que se apoia, chamado de Paxorô
        ou Opaxorô, que é uma simbologia utilizada para a separação do Orun e o
        Aiyê. O céu e a terra. Sua cor é o branco, seu dia é o domingo. Simbolo
        Maior do ano 2017: A Pomba Branca da Paz. Elemento é o Ar. A bebida é a
        água. Perfumes é o Alfazema, lírio branco e cálamo aromático. Seu
        domínio está no Orun(céu), ar e criação. Saudação é Êpa Babá! ou ainda
        Êpaô-Babá!. CARACTERÍSTICAS DO FILHOS DE OXALÁ URUMILAIA: POSITIVAS:
        Líder, tranquilo, benevolente, generoso, responsável, muitas vezes
        confuso. Extremamente inteligentes. Participativos. Planejadores,
        criativos. Calmos e pacificadores. Mesmo tendo facilidades para se
        adaptarem a qualquer situação de emprego, os de Urumilaia são exigentes
        consigo mesmo. NEGATIVAS: São péssimos executores, pois atropelam
        (apressados) as coisas que planejam e não dando certo de imediato
        abandonam a idéia e parte para outra coisa. Não ouvem opinião alheia.
        Magoa-se com facilidade com as pessoas que divergem deles e como sua
        vaidade, SEM SER ORGULHOSO, é enorme, não tiram satisfações de ninguém,
        pois se assim o fizessem notariam que a maioria das pessoas não queriam
        lhe magoar. Usam a falsidade como defesa. Não mentem, mas omitem. Não
        pedem ajuda a outros. Meticulosos, reacionários, exclusivistas. Não
        gostam de serem mandados e quando tem o poder nas mãos, são autoritários
        e fazem de tudo para manter o poder de mando. Gostam de ser o centro das
        atenções, ou seja, gostam de centralizar tudo ao seu redor.
        ACONTECIMENTOS MARCANTES DO ANO: Como Oxalá não entende de dinheiro o
        ano será um tanto difícil, principalmente para os que tem altos salários
        em virtude que Urumilaia não gosta de esbanjamento. Ano que no mundo
        todo haverá estudos aprofundados para a regulamentação de salários
        exorbitados para futuros profissionais o que evitará no futuro um caos
        mundial nas finanças estatais, ou seja, haverá uma organização rigorosa
        quanto a dinheiro e empregos. No amor haverá mais cautela em ambos os
        lados, ou seja, será com mais razão e menos paixão. A maldade, a
        crueldade, a mentira e o desamor não terão guarida neste ano de Oxalá. O
        ESPIRITUALISMO, PRINCIPALMENTE A CULTURA AFICANA SERÁ VALORIZADA E CADA
        VEZ MAIS RECONHECIDA NO MUNDO TODO. A inflação por ganância não haverá.
        Na agricultura, mais uma vez a soja e o trigo e desta vez o milho, serão
        destaques mundiais na produção agrícola. Comércio com água, artigos
        domésticos, construção civil, construção de estradas, imóveis, mesmo com
        a diminuição da margem de lucros, terá sucesso. A Classe política será
        muito policiada, coisa que aconteceu já no ano de 2012 quando Oxalá Lufã
        mandou e que no ano de Yemanjá Kayala a do Vento sobre o mar e
        justiceira que não perdoa, estourou tudo. Sofreram danos irreparáveis
        como foi profetizado em 2012. DOENÇAS DO ANO: Para as mulheres: extremas
        dificuldades para engravidar, ciclos menstruais desajustados. O máximo
        cuidado com útero e ovários. Para os homens: extremos cuidados com o
        estômago e intestino, funções digestivas em geral. Com Urumilaya
        (Orunmilá) ou ainda Orumilaya, haverá problemas também com rins.
        Incontinência urinária. Mais avanço na medicina sobre o tratamento da
        osteoporose. Mesmo com alguns acontecimentos negativos, será um ano de
        mais Paz, mais compreensão, mais tranquilidade.
      </p>
    ),
  },
  {
    year: "2016",
    title: "Iemanjá",
    img: iemanja,
    text: (
      <p>
        A GRANDE RODA DA VIDA, vai dar mais uma volta. Mais um ano que chega ao
        fim. Ano regido pelo orixá OGUM na égide de (A)NARUÊ, que dá fertilidade
        a terra, tanto que o Agronegócio foi o único seguimento da economia que
        se salvou, Os acontecimentos foram o que previmos, pois sendo Ogum
        Defensor dos Oprimidos, que entende pouco de dinheiro e sim de trabalho.
        Mesmo sendo um orixá de guerra, foram poucos conflitos e os que houveram
        foram mais amenos dos anos anteriores em que Ogum regeu. Com exceção da
        Siria e Afeganistao. Ficou escancarado porque faltou dinheiro para
        educação, saúde, segurança, que prejudica o povo oprimido pela falta
        destas assistências. Todos os governos, tanto os atuais como os próximos
        aprenderam uma lição amarga, “não deixe de favorecer os povos
        oprimidos”, pois o revés será duro e implacável. O tal de “pavio curto”
        perdurou por todo o ano e muitos perderam a vida pela falta de sensatez.
        A doença do ano foi, estomago intestino e fígado. Vamos agora, entrar no
        ano de 2.016 que será regido por YEMANJÁ. Em virtude do ano se iniciar
        numa sexta-feira que pela Tábua Cronológica da Nação de Cambinda, que
        consideramos a mais perfeita, o dia de sexta-feira é regido por Yemanjá.
        YEMANJÁ (Yéyé-omo-ejá – Mãe dos Peixes ou Mãe Filhos Peixes) Rainha das
        águas salgadas. Mãe de todas as Mães, Símbolo da Fertilidade, tem sua
        regência maior na Harmonia, Fertilidade, Moralidade, Justiça,
        Austeridade e Fartura. Único orixá no Brasil que tem sua imagem própria,
        mesmo sabendo que era sincretizada com Nossa Senhora dos Navegantes. As
        filhas de Yemanjá são voluntariosas, fortes, rigorosas, protetoras,
        altivas e algumas vezes impetuosas e arrogantes; tem sentido de
        hierarquia, fazem-se respeitar e são justas, mas formais; põem à prova
        as amizades que lhe são devotadas, custam muito a perdoar uma ofensa, se
        perdoam não esquecem jamais. Preocupam-se com os outros, são maternais e
        sérias. Sem possuírem a vaidade da Oxum, gostam do luxo, de roupas
        vistosas, das jóias caras. Elas têm tendências à vida suntuosa mesmo se
        as possibilidades do cotidiano não lhes permitem tal fausto. Dia da
        Semana: Sexta-Feira; Cor: Azul Claro(Celeste) e Branco; Saudação: Omy-ô!
        Odocy-Aba! Odôfy-Aba! Odô-Yá! Instrumento Símbolo: Abano, uma espécie de
        leque de metal branco, chamado de Abébé; Símbolo Cabalístico: Três Ondas
        do Mar. Defeitos das filhas de Yemanjá (registramos os defeitos
        inerentes ao orixá, para que se policiem), Apesar de maternais, com zelo
        admirável na defesa e cuidados com o esposo e seus filhos, muitas vezes
        se mostram por demais arrogantes. No trato do lar e de suas coisas se
        tornam chatas porque querem as “coisas no seu devido lugar” chegando a
        beira do fanatismo se alguém tira alguma coisa do lugar onde ela
        colocou. Tem uma sutileza irritante para criticar os erros dos outros.
        Irritante, pois logo em seguida se propõe em ajudar, Balneário
        Camboriú/SC, novembro de 2 0 1 5 mesmo não confiando nas pessoas. Como
        mãe e pelo excesso de zelo para com seus filhos, torna-se possessiva,
        mantendo os filhos sob um domínio, muitas vezes cruel, querendo dominar
        a personalidade deles, querendo que estes ajam de acordo como elas
        pensam, e quando isso não acontece, se tornam más, cruéis, ao ponto de
        perdê-los. E quando isso acontece se transformam em vítimas, fazendo
        chantagem emocional até conseguir a volta deste ou destes filhos. A
        YEMANJÁ de 2.016 será na égide de YEMANJÁ KAYALA, que a 42 anos não
        regia os destinos da humanidade. A regência maior e a presença da
        Kayala, está no vento por sobre o mar. Se não tiver vento ou uma brisa
        forte por cima do mar, a Kayala não estará ali. ACONTECIMENTOS MARCANTES
        NO ANO 2.016 – ANO DE YEMANJÁ KAYALA – Na sua regência, haverá: Amor,
        Harmonia, Austeridade, Fertilidade, Fartura e principalmente mais
        moralidade. Pois Yemanjá também é Justiça. A moralidade se apresenta por
        ser este orixá muito austero. Este orixá não gosta de solidão, por isso
        as pessoas que ainda não encontraram sua cara metade, terão um ano
        propício para isso, não importando a idade. Mulheres que encontram
        dificuldades para engravidar, terão neste ano a grande oportunidade,
        tanto normal como em fertilização. E, as que não quiserem engravidar que
        tomem severas precauções, pois mulheres com até 52 anos de idade, sendo
        filhas de Yemanjá, poderão engravidar. Estabilidade emocional e
        financeira. Será um ano de austeridade severa. A produção de alimentos
        no mundo todo alcançará níveis ótimos, o que proporcionará redução nos
        preços ou pelo menos manter os que estão agora. O espiritualismo, a
        moda, tratamentos estéticos, atividades artísticas, comércio de imóveis
        para casa própria, a pesca, frutos do mar, processos legais como
        precatórios, federais, estaduais e municipais, terão uma atenção
        especial por parte dos governantes, pois Yemanjá além de Justiça é
        Equilíbrio. O Direito, ou seja, advogados serão altamente prestigiados.
        Ano em que o câncer de mama, deverá ser erradicado definitivamente, pois
        será o ano em que a medicina alcançará o instrumento final para acabar
        com esse mal. ACONTECIMENTOS NEGATIVOS – Em virtude da falta de respeito
        da humanidade com a natureza, o mar poderá se revoltar novamente,
        causando estragos perigosos como tsunami, pois os níveis em todos os
        mares do mundo começarão a se elevar. Doenças mais acentuadas no ano
        serão: Artrite, Artrose, Osteoporose, Próstata, varizes e trombose. Em
        contra partida, para quem já sofre destes males, terão a oportunidade
        curar. O ano será chuvoso, quente, muito além do normal e ventos, sendo
        mais ventos do que chuva. Muitos acidentes em terra e no ar (desde 15 de
        outubro/15 para cá já vem acontecendo). Para evitar estes acontecimentos
        negativos, devem agradar a Yemanjá, no dia 31 dezembro/15, vestir uma
        roupa azul claro, pode ser até roupa intima ou portar algum objeto na
        cor azul claro que é a magia das cores deste orixá. Os orixás não são
        divinos, eles são divinizados, por isso que requerem respeito e
        confiança neles, fazendo estes pequenos gestos de fé. Dentro das
        possibilidades, acendam uma vela azul claro, minutos antes da meia-noite
        do dia 31/12/2015, para entrar no pino da hora grande (meia-noite),
        agradando a Yemanjá. Fazendo seus pedidos com fé e confiança. Em virtude
        deste ano ser bissexto o próximo ano 2.017 vai ser regido por Oxalá.
      </p>
    ),
  },
  {
    year: "2015",
    title: "Ogum",
    img: ogum,
    text: (
      <p>
        A GRANDE RODA DA VIDA vai dar mais uma volta, mais um ano que chega ao
        fim. Ano de Oxossi e Yansã, que foi tudo aquilo que previmos para 2.014,
        ano da saúde, pois Oxossi é o Dono da Terapêutica do espaço e a medicina
        teve um avanço notável sob a sua regência. Tivemos a prevenção do HPV
        nas escolas, como previmos, a medicina teve um avanço extraordinário
        para a cura da cruel doença do Alzheimer, pesquisada no Hospital Geral
        de Massachusets da Universidade de Harvard nos Estados Unidos. O
        professor Yves Levy e professor Didier Raoult da Faculdade de Medicina
        de Marselha na França, num estudo profundo encontraram um caminho para a
        cura da Aids. A medicina obteve um sucesso extraordinário ao retirar do
        nariz de um paraplégico, como célula tronco, e implantando naquele
        doente e fazendo com que ele ficasse em pé de desse os primeiros passos.
        Tudo isso e muito mais só poderia acontecer no ano em que Oxossi viesse
        a mandar e.. Aconteceu! Isso que tinha gente dizendo que o ano seria
        regido por Xapanã, se fosse este Orixá Menor, nada disso teria
        acontecido. Sabemos que tudo isso só se concretizará definitivamente
        quando Oxossi vier a mandar novamente. A doença terrível que também
        previmos, infelizmente aconteceu, o Ebola transmutado, veio matando e
        somente neste final de ano que a medicina esta encontrando os meios para
        neutralizar esta doença terrível. Na política como afirmamos nenhum dos
        candidatos a Presidência da República estariam beneficiados pela força
        do orixá do ano, pois nenhum deles tinha nada a ver com Oxossi e Yansã e
        foi o que aconteceu, vitória com mínima diferença de quem venceu, apenas
        em torno de 3%. Agora vamos entrar no ANO DE OGUM: Orixá Senhor da
        Guerra, vencedor de demanda, defensor dos oprimidos. Será um ano
        extremamente nervoso, pois vejamos as características dos filhos de
        Ogum: Os filhos de Ogum são alvissareiros e belicosos como o próprio
        Orixá. Arrojados, metidos e muitas vezes bravos e exageradamente
        briguentos. Não suportam a força em nenhum sentido esmagar os indefesos.
        São defensores natos dos oprimidos. Rápidos de raciocínio e descuidados
        em emitir suas opiniões, pois não escolhe lugar e nem hora, para dizer a
        verdade a quem quer que seja. Gostam muito da expressão “doa a quem
        doer” e pela sua imponência e muitas vezes arrogante, dói nele primeiro.
        Reage com extrema rapidez aos impulsos o que geralmente lhe leva as
        situações constrangedoras e perigosas. Definindo a impulsividade dos
        filhos de Ogum, nós dissemos que se alguém lhe bate a porta, estando ele
        pelo lado de dentro, ele não pergunta quem é ele abre a porta para ver
        quem é. Autoconfiante, enérgico e possessivo. Egocêntrico natural gosta
        de receber elogios bem como não economiza elogios para tudo aquilo ou
        aqueles que eles gostam. Um excelente faro para arquitetar as coisas e
        colocar dinamismo nas suas palavras e nas ações. É tão rápido em suas
        análises sobre tudo que imediatamente fala aos que estão por perto.
        Filho de Ogum tem que ser muito bem doutrinado, para que possa conter os
        seus defeitos, que são basicamente a fúria e o ódio. Coisas que
        geralmente lhe leva a destruição de si mesmo. São pessoas violentas,
        briguentas e impulsivas. Encontram dificuldades imensas para perdoar as
        ofensas que foram alvos. Filho de Ogum triunfa naqueles momentos em que
        qualquer outro teria abandonado o combate e perdido a esperança. São por
        demais sinceros e francos em suas intenções e talvez por isso, tornam-se
        difíceis de serem odiados. Possuem um grande senso de responsabilidade e
        dedicação à família, fazendo grandes sacrifícios para que nada falte aos
        que lhe rodeiam. Defeitos: Os defeitos dos filhos de Ogum são os piores
        possíveis e residem no seu temperamento explosivo e violento. É
        extremamente necessário que se espiritualize se policie, para aprender
        em se conter. INFORMAÇÕES SOBRE ORIXÁ OGUM: Dia da Semana: QUINTA-FEIRA
        – Cor: VERMELHA– Saudação: Ogum-Yê! Patacori Ogum! – Instrumentos
        Símbolos de Ogum: O ferro, o Aço, e a Enxada – Símbolo Cabalístico: A
        Espada – Sincretismo: São Jorge. ACONTECIMENTOS MARCANTES DO ANO 2.015 –
        ANO DE OGUM – O ANO será regido por Ogum, porém na égide de OGUM
        (A)NARUÊ, O ORIXÁ QUE DÁ FERTILIDADE A TERRA, a terra no mundo todo está
        cansada, estamos correndo sérios riscos para a produção de alimentos que
        vem da terra. A 40 anos que Ogum Anaruê, ou Naruê, como queiram não
        manda no mundo e para alegria nossa, ele volta agora em 2.015. Assim
        sendo os conflitos que haverá sem dúvidas nenhuma, serão mais amenos em
        virtude de ser esta força de Ogum que estará pairando sobre o Universo.
        No campo financeiro será um ano bem melhor que o ano que esta findando,
        mas também não será de muitos sucessos financeiros, pois Ogum entende
        melhor de trabalho e muito pouco de dinheiro, mas repito, será melhor
        que o ano de Oxossi que está findando. O governo mais uma vez terá que
        encontrar instrumentos para deter a inflação que corremos o grande risco
        de disparar. Dificilmente haverá aumento de salários, haverá muitas
        frentes de emprego, mas com salários apenas razoáveis. Profissões que
        estarão valorizadas: AGRONOMIA – VETERINÁRIA - AERONÁUTICA – EXÉRCITO
        (altamente valorizado) – POLÍCIA CIVIL E MILITAR – COMPUTAÇÃO –
        INFORMÁTICA - ENGENHARIA DE PRODUÇÃO – ENGENHARIA METALÚRGICA. Produção
        de grãos e alimentos de vem da terra. Construção de Estradas –
        Comercialização de Máquinas Pesadas. COISAS RUÍNS DO ANO - Será um ano
        marcado pelas transformações no mundo, muitas delas pelos conflitos que
        acontecerão em busca da verdade, contra as especulações maldosas que
        judiam do povo. Se os países poderosos do mundo todo não conseguirem
        acabar com os conflitos sérios que estão acontecendo na Ucrânia,
        estaremos sujeitos a assistir um avanço sangrento de batalhas à beira de
        uma guerra. OGUM É DEFENSOR DOS OPRIMIDOS, ele não deixará barato o
        massacre dos pequenos em todos os níveis e em qualquer lugar do mundo.
        Haverá muitos tremores de terra em lugares que nunca houve. A descarga
        de raios aumentará consideravelmente no Brasil e algumas mortes,
        infelizmente ocorrerão. No campo das doenças, o mal estará localizado no
        estômago, intestino e fígado. O nervosismo extremo acarretará muita
        violência. Todos terão que se policiar nos momentos de elevado estresse,
        conter o chamado “pavio curto”. Pequenos incidentes, principalmente no
        trânsito e com vizinhos, poderão tornar-se num irreparável incômodo que
        poderão a levar morte. Todo cuidado será pouco. Afirmamos que quem tem
        CRUZAMBÊ, iluminado 24 horas por dia, cuidado com todo o carinho,
        desvelo, amor pelo próximo, seriedade e conhecimento, estará livre
        destes nefastos acontecimentos que inevitavelmente acontecerão.
      </p>
    ),
  },
  {
    year: "2014",
    title: "Oxossi",
    img: oxossi,
    text: (
      <p>
        O próximo ano (2014) será regido por Oxossi e Yansã, em virtude do ano
        se iniciar uma 4ª feira, sendo que pela Tábua Cronológica da Nação de
        Cambinda(que consideramos a mais perfeita, mesmo praticando a Umbanda de
        Omolocô) . Mesmo estando a Yansã no segundo plano, o ano correrá nos
        maiores acontecimentos pela égide Oxossi, que é quem estará em primeiro
        plano. OXOSSI, Rei das Florestas – Caçador de Almas – Rei das
        Matas,Reide Kêto e regente da terapêutica do espaço. Os filhos de
        Oxossi, cuja numerologia são apenas duas somas: 26 e 28, são
        desconfiados, fiéis aos seus amigos, extremamente cuidadosos em tudo,
        preocupa-se com o dia de amanhã. Bom amigo, calmo e explosivo. Quando
        Calmo chega a irritar, quando Bravo vai as raias da ignorância. Por
        estudos profundos que fizemos e outros estudiosos também fizeram, o
        filho de Oxossi traz um estigma ruim, ou seja, nasceram para serem
        solteiros, pois está sempre em busca da mulher perfeita que só existe na
        cabeça deles. No panteão africano, existe e esta é a verdade, 7 (sete)
        Orixás Maiores, sendo 4(quatro) masculinos e três (3) femininos. Sendo
        que os casais “perfeitos” ou quase perfeitos são: OXALÁ com a YEMANJÁ,
        OGUM com a YANSÃ, XANGÔ com a OXUM, sendo Oxossi o 4º orixá masculino,
        não tem parceira, ou seja, pode casar com qualquer uma das três:
        Yemanjá, Yansã e Oxum, porém tem dificuldades de fazê-las feliz, por ser
        um homem “diferente”. Depois de algum tempo casado, ele próprio se torna
        um homem infeliz. No campo da amizade, os filhos de Oxossi, são os
        melhores amigos que se pode ter, pois são de absoluta confiança. Dizem
        que os cultuadores de Oxossi, na África estão em extinção, mas não é bem
        isso que está ocorrendo. Na realidade eles são raros, pois apenas duas
        somas da numerologia é que se dá para filho de Oxossi: 26 e 28. Assim
        sendo, são realmente, muito poucos os filhos de Oxossi, no mundo todo.
        Características dos Filhos de Oxossi: É amável e sincero para com os
        amigos. É capaz de tirar de si para ajudar um amigo. Estatura mediana
        para alta. Lábio inferior geralmente grosso. Pele delicada e morena.
        Individualista. “Caçador de si mesmo”. DEFEITOS: Os defeitos nos filhos
        de Oxossi, é que são um tanto “fantasiosos” com os problemas alheios. E
        um dos piores defeitos dos filhos de Oxossi, é a “acomodação”, ou seja,
        não tem princípios de ganância (o que não seria defeito em algum
        momento), aceitam o pouco que tem e paradoxalmente aí reside a
        inconstância dele, gostam muito de mudar de residência e de novos
        empregos. Dificilmente são fiéis as suas parceiras, porque estão
        sujeitos a paixões “relâmpagos” á “primeira vista”. Desdenham pessoas
        preguiçosas e caseiras. Casa muitas vezes apenas para não ficarem na
        “solidão”. Galanteadores amáveis. São ligeiramente preguiçosos. Muitas
        vezes “aumenta” ou “inventa” coisas. Consegue fazer várias coisas ao
        mesmo tempo, mas dificilmente termina algo. Tem imensas dificuldades em
        usar as palavras “meu amor”, “minha querida”, enfim de dialogar com a
        companheira, mesmo amando-a. Porém quando perde a pessoa amada, entra em
        “parafuso”, ou seja, só valoriza a companheira quando a perde.
        ACONTECIMENTOS MARCANTES NO ANO: Será o ano da Saúde, da sorte, da
        prosperidade, da amizade. A Medicina terá um avanço sensível, na cura e
        na prevenção nas doenças de Alzheimer, HPV (que ainda não tem nenhuma
        prevenção), avanço total na cura (erradicação) do câncer de mama. Avanço
        mundial do espiritualismo, a Reencarnação terá atenções do mundo todo,
        engajando-se religiões que ainda não aceitam ou tem restrições sobre o
        assunto. Valorização e Reconhecimento do homossexualismo, onde muitos
        povos aceitarão como fato natural e não como doença, pois realmente
        doença não é. A cultura africana ensina que o homossexual nada mais é do
        que o homem que nasce com orixá feminino na frente e a mulher nasce com
        orixá masculino na frente e isso não é doença é NATURAL. Atividades e
        Profissões valorizadas: Todas que se relacionarem com ervas e plantas e
        ainda, Pesca. Veterinária. Jornalismo. Marketing. Turismo. Ecologia e
        Representante Comercial. Na política, ano de eleições presidenciais,
        nenhum dos 4 (quatro) prováveis candidatos levarão alguma vantagem, pois
        pela numerologia, conforme datas de nascimento que chegou em minhas
        mãos: A Dilma, soma 29, é filha de Yemanjá; Aécio soma 20, é filho de
        Oxalá; Eduardo Campos, soma 30 é filho de Xangô e Marina: soma 33 é
        filha de Oxum. Portanto, nenhum tem nada com Oxossi e/ou Yansã. COISAS
        RUÍNS DO ANO: Doenças: Estômago e regiões das pernas, joelhos e pés.
        Aumento considerável (perigoso) do aquecimento global, principalmente no
        2º semestre. Vendavais, ciclones, tufões e terremotos, acima da média.
        Os aviões vão enfrentar muitas turbulências assustadoras, que poderão
        causar acidentes aéreos acima do normal. Irá aparecer uma nova doença
        fatal e altamente perigosa, uma espécie de transmutação da Aids. OXOSSI:
        Dia da Semana: 4ª Feira – Cor: Verde – Sincretismo: São Sebastião
        Símbolos Cabalísticos: Arco – Flexa – Penas – Dentes de Animais
        Selvagens – Saudação: Ókê Arô!! YANSÃ: Dia da Semana: 4ª Feira – Cor:
        Azulão – Sincretismo: Santa Barbara Símbolos Cabalísticos: Abano
        (Leque)- Raios – Espada Gamada – Saudação: Eparrêy! Epayêyo!!
      </p>
    ),
  },
  {
    year: "2013",
    title: "Xangô",
    img: xango,
    text: (
      <p>
        O próximo ano será regido por XANGÔ apenas 5 anos depois ele volta a
        reger os destinos da humanidade o XANGÔ AGODÔ, o da JUSTIÇA VERDADEIRA.
        Quem em 2.008 não teve um ano bom, mas não perdeu a fé, nesta volta do
        Agodô será muito bem recompensado. Implacável, justo e sábio. Os filhos
        de Xangô são de um caráter violento e imperioso. Viril e atrevido.
        Justiceiro por excelência. Castiga os mentirosos, ladrões e malfeitores.
        Todos que se sentirem injustiçados devem recorrer a Xangô, pois na
        dureza da pedra, simboliza a inflexibilidade da Lei e da Justiça. É uma
        força enorme que de uma forma sensata e justa, dá a quem é devido o que
        lhe é de direito. Sabem o que querem e é muito difícil de serem
        enganados. Tem suas opiniões próprias, irredutível nos seus argumentos e
        acha sempre que está com a razão. Precipitado nas suas ações e faz de
        tudo para por em descrédito seus inimigos. Não tem por método em confiar
        nos outros. No lar ou em seu ambiente de trabalho, consegue respeito,
        porém consegue também muita indignação, por ser considerado chato, pois
        quer tudo sempre em seu devido lugar, não admitindo alguém mexer em
        qualquer coisa e objeto que tenha ali colocado. Tem facilidade muito
        grande no que diz respeito ao sexo feminino. Em virtude de ser
        galanteador. Egocêntrico, metódico e extremamente correto. Gostam de
        impor suas opiniões sem discuti-la. Não perdoam a ignorância de ninguém.
        Sendo egocêntrico, crê em si mesmo e pensa que tudo que faz ou sente é
        absolutamente certo, deixando-se levar pela ilusão e sonhos. Não toleram
        a menor contradição e não raramente toma-se de cólera, que se torna até
        incontrolável. Seguidas vezes torna-se intratável e inflexível e jamais
        admitirá que esteja errado. Não existe meio termo para Filho de Xangô,
        ou você admira-o ou não suporta sua presença. CARACTERÍSTICAS DO XANGÔ:
        Dia da Semana: Terça-Feira; Cor: Marrom, marrom e branco; Sincretismo:
        São Jerônimo; Símbolo: Balança e Machado de duas Lâminas (Oxó, Oxé e
        Osé). Este é o AXÉ DO ANO Vocês provavelmente irão ouvir na mídia ser um
        outro Orixá o Regente de 2013, em virtude de que no Centro do País,
        alguns ainda usam a Tábua Cronológica dos Orixás das Nações de Nagô e/ou
        Angola, que já está ultrapassada, pois trocam o dia da semana de
        terça-feira de Xangô, por Ogum e as quintas-feiras de Ogum por Xangô,
        sendo que a de Cambinda (a que usamos), é mais perfeita, mais lógica,
        dando mais fundamento aos acontecimentos do ano que começa numa
        terça-feira. ACONTECIMENTOS MARCANTES DO ANO: O ano será essencialmente
        da Justiça Perfeita. Desde 15 de outubro , Xangô Agodô já vem “entrando”
        e dando mostras de que como será o ano de 2013 (vejam o julgamento do
        mensalão) roubos, mentiras, corrupções, estelionatos, enfim toda e
        qualquer maldade no sentido de lesar alguém será castigada. A impunidade
        será policiada com grande intensidade pela Justiça Implacável de Xangô.
        Haverá muita cobrança contra políticos corruptos, que finalmente muitos
        irão pagar caro (PRISÃO) os seus erros. Ano da persistência e da
        disciplina. O ser humano terá que agir com organização, paciência e
        determinação, além de ponderar para fazer escolhas certas e tomar
        decisões sensatas. Haverá um movimento gigantesco no sentido da
        moralidade. Muito cuidado com a precipitação, agressividade e violência
        descabida, pois isso poderá causar obstáculos enormes para as suas
        conquistas. Não haverá espaço para traições, mentiras, desamor. Por isso
        haverá escândalos amorosos nos falsos casais (que se suportam, mas não
        se amam) de pessoas importantes e conhecidas do mundo todo. Muitas
        dificuldades o governo encontrará no trato relacionado com a saúde,
        hospitais e medicamentos. As emoções e sensualidade estarão fortalecidas
        neste ano. CUIDADO! Incêndios desproporcionais deverão ocorrer, pois
        mesmo não sendo o Kamwkay (Xangô do Fogo), assim mesmo é XANGÔ! Em 2013,
        continuará a polêmica gigantesca, que envolverá Sociedade, Igreja
        (Religião) e Judiciário: A LIBERAÇÃO DAS DROGAS (Menos as sintéticas),
        ATIVIDADES QUE ESTARÃO ALTAMENTE PROTEGIDAS E COM MAIOR SUCESSO:
        Magistratura, Promotoria, Advocacia, Matemática, Política e
        Fiscalização. Construção Civil e Estradas. Móveis e utensílios.
        Prudência em investir em alguma coisa que não traga benefícios em curto
        prazo. Exercite a paciência e o respeito pelos entes queridos. DOENÇAS
        DO ANO: São as relacionadas com o coração. Ansiedade, hipertensão e
        impotência. Respeitar os limites para descansar, pois estaremos
        vulneráveis a derrame cerebral e infartos do miocárdio.
      </p>
    ),
  },
  {
    year: "2012",
    title: "Oxalá",
    img: oxala,
    text: (
      <p>
        Vamos entrar então, no ANO DE 2012, Ano de Oxalá, que vem na vibração de
        Oxalá Lufã, o velho, o sábio. O ano se inicia num domingo e por isso
        será regido por Oxalá, pois este é o Orixá regente do dia de domingo.
        OXALÁ é Regente da Paz, do Sorriso e da Tranquilidade. Considerado o
        Maior de Todos os Orixás. Sábio e austero. Na condição de velho e sábio,
        curvado ao peso dos anos, figura nobre e bondosa, carrega um cajado em
        que se apóia, o Paxorô, ou Opaxorô, cajado de forte simbologia, sempre
        utilizado para a separação do Orun e o Ayié. O céu e a terra. No Brasil
        é o mais venerável bem como o mais venerado, sua cor é o branco, seu dia
        é domingo, pela Tabua Cronológica da Nação de Cambinda, que é a que
        seguimos. Símbolo maior do Ano 2.012 é o cajado, o Opaxorô ou Paxorô,
        sendo que em cima deste, está a Pomba Branca da Paz. Elemento é o Ar. A
        bebida é a água. Perfumes é a Alfazema, lírio e cálamo aromático. Seu
        domínio está no Orun (céu), ar e criação. (Saudação é Xêuêpa Babá (o
        velho) bem como também Êpa Babá, que é usada mais para Oxaguiã, o Oxalá
        moço). CARACTERÍSTICAS DOS FILHOS DE OXALÁ: Líder, benevolente,
        generoso, , responsável, confuso, ansioso, rígido, hipocondríaco.
        POSITIVAS: São extremamente inteligentes. São participativos. Ótimos
        planejadores, criativos. Geralmente calmos e pacificadores, são capazes
        de se adaptarem a qualquer situação, emprego, pois aprendem com muita
        rapidez. NEGATIVAS: São péssimos executores, pois atropelam (apressados)
        as coisas que planejam e não dando certo de imediato, abandonam a idéia
        e partem para outra coisa. Não ouvem opinião alheia. Magoa-se facilmente
        com as pessoas que divergem deles e como sua vaidade é enorme, não tiram
        satisfações, pois se assim o fizessem notariam que ninguém queria lhes
        magoar. Usam a falsidade como defesa. Não mentem, mas omitem.
        Meticulosos, reacionários exclusivistas. Não gostam de serem mandados e
        quando tem o poder na mão, chegam a ser cruéis. Usam sobre seus
        comandados, inverdades, sarcasmos, sempre para manter o poder de mando.
        A nossa intenção de registrar as características negativas é para que os
        filhos de Oxalá se policiem ao máximo, evitando assim muitos dissabores
        em suas vidas. ACONTECIMENTOS MARCANTES DO ANO: Sendo Oxalá Lufã o
        mandante, os casais que vivem as turras, estarão mais sensibilizados em
        manter um diálogo sensato, procurando se entenderem melhor, buscando
        onde estão às divergências, os erros de cada um. O ser humano ficará
        mais cauteloso nos assuntos do amor. Mais razão e menos paixão. Ano de
        grande mudanças para a raça humana. A maldade, a crueldade, a mentira, o
        desamor, não terão vez no ano de Oxalá. O espiritualismo, cultura
        africana, ganhará um lugar de destaque na humanidade, pois as
        transformações serão enormes. O crescimento da espiritualidade será
        notável, não de religiosidade, porque a religião não está dando mais
        respostas ás pessoas. Oxalá é um orixá que não entende de dinheiro, ou
        seja, não rege nada neste sentido, por isso que teremos que rezar muito
        para que a crise que esta abalando o estrangeiro não venha nos atingir,
        e para isso o governo federal terá que achar instrumentos fortes para
        não deixar que a inflação se instale em nosso Brasil. Ano perigoso para
        dinheiro. Não façam nenhum empreendimento sem consulta. Mais uma vez na
        agricultura a soja e o trigo serão destaques mundiais na produção
        agrícola. Aqueles que trabalham junto às classes sociais mais populares,
        notadamente as atividades voltadas aos idosos, mulheres e crianças,
        serão altamente valorizados. Quem trabalha com comércio de água, artigos
        domésticos, construção civil, construção de estradas, imóveis, terá
        sucessos em suas atividades, mesmo que o lucro será menor do que o
        esperado, pois Oxalá não admite ganância. A classe política sofrerá
        danos irreparáveis, com certeza aos maus políticos. As doenças do ano
        serão: para as mulheres, dificuldades na gravidez, ciclo menstrual,
        útero e ovários. Para os homens, extremos cuidados com o estômago e
        intestino, funções digestivas em geral. Ano em que a medicina vai
        avançar de uma maneira surpreendente no tratamento da osteoporose.
        Finalizando registramos que o propalado fim do mundo em 2.012, não
        acontecerá, o que houve foi a má interpretação das profecias dos Maias,
        que registraram até dia 21.12.2012 o que alguns acham que ali então
        seria o fim do mundo, pois a partir dalí não tem mais nada escrito pelos
        Maias. Porque profetizaram somente até 21.12.2012 não sabemos o motivo.
      </p>
    ),
  },
  {
    year: "2011",
    title: "Oxum Maré",
    img: oxum,
    text: (
      <p>
        A GRANDE RODA DA VIDA VAI DAR MAIS UMA VOLTA, mais um ano que chega ao
        fim. O de Yemanjá (2010) também foi tudo aquilo que previmos amor,
        harmonia e fartura. Pouco se ouviu falar em fome do ser humano no
        planeta terra. Muitas pessoas solitárias encontraram seu par ideal, e os
        que não encontraram ainda, terão nova oportunidade neste ano novo de
        2.011 ANO DA OXUM. Os relacionamentos foram e ficarão ainda mais
        harmoniosos neste ano do Amor que esta chegando. O espiritualismo se
        alavancou de uma maneira surpreendente, vejam só o sucesso retumbante do
        filme NOSSO LAR. A produção de alimentos bateu todos os recordes no
        mundo todo. O Mar novamente se revoltou, houve o tsunami que previmos na
        Indonésia, já com menor impacto, pois o ser humano esta aprendendo mesmo
        que devagar a respeitar a natureza. Foi um ano da mulher, no mundo todo
        a mulher se destacou, no Brasil deu pra ver facilmente a força da mulher
        senão vejamos; o crescimento inesperado da candidata a presidência
        Marina Silva e a eleição da primeira presidenta do Brasil Dilma Roussef
        e vem aí mais um ano da mulher: ANO DA OXUM. Características das filhas
        de Oxum:Vamos entrar no ano de 2011, ANO DE OXUM MARÉ (ORIXÁ MEIO HOMEM,
        MEIO MULHER) – DEUSA DO AMOR, DA BONDADE E DO OURO. Regente dos Rios,
        Águas Doces, Lagos, Riachos e Lagoas. Simboliza a graça e a beleza. Na
        Nigéria existe um rio denominado Oxum. OXUM - ”ÒSUN” – A Grande Deusa e
        Rainha de todos os Rios e Cachoeiras, com grandes poderes sobre a água
        doce, sem esta não haveria vida sobre a terra. As filhas de Oxum são
        vaidosas, algumas ao extremo, como as Pandás, pois “limpam suas jóias,
        antes de limparem seus filhos”. Existem algumas diferenças entre as
        Oxuns: Oxum DOKÔ: São as que carregam com mais propriedade as
        características de todas as Oxuns, suas virtudes e seus defeitos. OXUM
        PANDÁ: É a mais jovem, faceira, tem graça e beleza. Coquete. Gosta de
        jóias e música, de perfumes e roupas caras, são elegantes. OXUM NANÃ: É
        a “velha”. São umas tanto reservadas, tanto no vestir como no falar e
        agir. Sofisticadas e “rabugentas”. Pensamentos e ações de pessoas com
        idade avançada. Não gostam de ter muitos filhos, porém gostam de cuidar
        e zelar pelos filhos dos outros. OXUM MARÉ-A GRANDE REGENTE DE 2.011 -
        (Meio homem, meio mulher), encontram muita dificuldade no campo
        sentimental, em virtude da ambigüidade em que vivem. No sincretismo
        religioso é NOSSA SENHORA APARECIDA. OXUM DEMW (DEMÚ) – Relacionada com
        demônios e bruxas. Temperamento forte. Guerreira e poderosa. Voluptuosa
        e sensual. Sua aparência graciosa e sedutora esconde uma vontade muito
        forte de ascensão social sem medir conseqüências. Ela tem muito de
        Yansã/Oyá. DEFEITOS: Mesmo não tendo muitos defeitos, as filhas de Oxum
        demonstram fragilidade e insegurança em sua sexualidade. Mesmo que
        algumas se mostram voluptuosas e sensuais, relutam muito em aceitar os
        homens. “Desdenham o que querem comprar”. Fazem-se de difíceis no
        momento de assédio, mesmo que ela tenha provocado. São por demais
        emotivas e tem facilidade de derramar lágrimas. Gosta de fazer o bem,
        porém sem muita análise das pessoas, o que às vezes se dá mal. Gosta de
        agradar a si mesma e com extrema vaidade. Dependendo de qual Oxum
        pertence, fica horas a frente de um espelho, experimentando todos os
        estilos de penteados e roupas. Tingir os cabelos é com ela mesma e adora
        o loiro. ACONTECIMENTOS MARCANTES DO ANO: Assim como foi o ano de 2.010,
        Ano de Yemanjá, será mais um ano da Mulher, mesmo que desta vez será
        dividido estas forças, entre homem e mulher. Sendo a Oxum Deusa do Amor,
        este ano será do amor perfeito, os homossexuais serão valorizados e
        respeitados como seres normais que realmente são, o mundo começa a se
        prostrar perante o amor verdadeiro que existe entre os homossexuais, que
        tem enfrentado a intolerância, a desconfiança, e às vezes a repulsa da
        sociedade e algumas religiões. Com a Oxum Maré regendo, não haverá
        separações de casais como houve em 2.005 quando Oxum Pandá regeu a vida.
        Por outro lado, os casais que se amam, porém vivem as turras,
        desconfianças, se unirão num laço de amor puro. Até aqueles que estão
        separados, por motivos alheios a sua vontade e que se amam, porém não se
        dão conta, vão voltar a viver juntos novamente. Aquelas pessoas (homens
        e mulheres) que vivem sem amor, sem parceiros, terão nova oportunidade
        de encontrar sua cara metade. Ano de progresso para a humanidade
        inteira. Ano calmo bom para refletir e reforçar idéias nas metas
        empenhadas neste ano que finda. Nossas percepções estarão aguçadas,
        atentos a tudo que acontece em nossa volta. Nada passará despercebido.
        Estaremos mais responsáveis. A fecundidade (fertilidade) será destaque
        no mundo inteiro. A capacidade de argumentar, compartilhar idéias e
        trocar experiências estará em alta nas nossas vidas. A busca por
        novidades e querer fazer muitas coisas ao mesmo tempo, poderá atrapalhar
        a concretização de objetivos. A humanidade se voltará para as coisas da
        espiritualidade. O espiritualismo terá um avanço gigantesco. O estudo e
        a aceitação sobre re-encarnação começa a tomar conta da humanidade,
        independente de credo religioso. Haverá uma pequena, mesmo sendo
        notável, inflação nos alimentos. Na área de comércio, o ramo de imóveis,
        artesanatos, jóias, salão de beleza, cirurgias plásticas, alimentação e
        artigos femininos, serão os mais bem sucedidos. A beleza e a vaidade
        tanto de mulheres como homens estarão em alta. Mulheres e também homens
        que nunca entraram num salão de beleza, procurarão estes profissionais
        da beleza. A comunicação estará muito ativa, bem mais do que agora,
        favorecendo quem trabalha no comércio, na mídia e em outros campos nos
        quais a fala ou a escrita são importantes. Na área de agropecuária
        haverá surpresas agradáveis. Avicultura e Suinocultura. Soja e trigo. Os
        poderosos continuarão diminuindo suas margens exorbitantes de lucros o
        que irá gerar mais empregos. A valorização da água doce atingirá seu
        clímax no mundo inteiro, até com certa incoerência, em virtude da ação
        extrema dos defensores do meio-ambiente. Deverá ser um ano de poucas
        chuvas. A seca fará com que o ser humano valorize mais a água potável.
        As doenças do ano estão relacionadas com o baixo-ventre: para as
        mulheres: útero, ovários e trompas, para os homens: próstata. Cuidados
        especiais com, nervos, sistema respiratório, cordas vocais e ansiedade.
        Você querendo agradar a Oxum Maré, no dia 31.12.2010 à meia-noite, vista
        uma roupa na cor Amarela,(Magia da Cor do Orixá Regente), camisa,
        camiseta, vestido, calça, até mesmo uma roupa íntima e acenda uma vela
        na cor amarela, uns 15 minutos antes da meia-noite, para entrar o ano
        com a vela acesa, e faça os teus pedidos no momento certo. Os Orixás não
        são Divinos, são divinizados, por isso que é necessário agradá-los com
        oferenda, mesmo que singela, como uma simples vela na cor do Orixá
        regente. Creia, faça com fé, amor e respeito e confiança. Tenham um BOM
        ANO DA OXUM MARÉ(2011) Deusas das Cachoeiras, rios, lagos e lagoas, cujo
        símbolo maior do ano, ALÉM DO CORAÇÃO é o ARCO ÍRIS. Que Oxalá nos
        abençoe a todos!
      </p>
    ),
  },
  {
    year: "2010",
    title: "Yemanjá",
    img: iemanja,
    text: (
      <p>
        Ano da Harmonia, Fertilidade e da Fartura O ano de 2.010, como se inicia
        numa sexta-feira, e pela Tabua Cronológica dos Orixás da Nação de
        Cambinda, que consideramos a mais perfeita, será regido por YEMANJÁ,
        (Yê-Yê-omo-ejá – Mãe dos Peixes ou Mãe-Filhos-Peixes), Rainha das Águas
        Salgadas , Mãe de Todas as Mães – Símbolo da Fertilidade, exerce sua
        regência sobre: Harmonia e Fartura. Hoje, na Umbanda e em algumas Casas
        de Candomblé, é o único Orixá que tem sua imagem própria nos Altares,
        sem sincretismo (antigamente sincretizava com Nossa Senhora dos
        Navegantes): Uma bela mulher, saindo das águas do Mar, com vestimenta em
        Azul Claro, com colo nu, espargindo estrelas de ambas as mãos. Na África
        e em Cuba ela é representada nas imagens com o aspecto de uma matrona,
        de seios volumosos, símbolo de maternidade fecunda. As filhas de Yemanjá
        são voluntariosas, fortes, rigorosas, protetoras, altivas e algumas
        vezes impetuosas e arrogantes; tem sentido da hierarquia, fazem-se
        respeitar e são justas, mas formais; põem à prova as amizades que lhe
        são devotadas, custam muito a perdoar uma ofensa, se perdoam não a
        esquecem jamais. Preocupam-se com os outros, são maternais e sérias. Sem
        possuírem a vaidade da Oxum, gostam do luxo, das fazendas azuis e
        vistosas, das jóias caras. Elas têm tendência à vida suntuosa mesmo se
        as possibilidades do cotidiano não lhes permitem tal fausto.
        Características Físicas das Filhas de Yemanjá: Estatura de media para
        baixa em sua grande maioria. Rosto cheio, braços ligeiramente finos, pés
        pequenos, nariz um tanto arrebitado, gostam de cabelos longos. Seios
        robustos. Dia da Semana: Sexta-Feira; Cor: Azul Claro (Celeste) e
        Branco. Saudação: Omy-ô! Odôcy-Ába! Odô-Yá! Instrumento Símbolo: Abano
        (uma espécie de leque) de metal branco: Abebé; Símbolo Cabalístico: Três
        Ondas do Mar. Defeitos das filhas de Yemanjá: Apesar de serem maternais,
        com zelo admirável na defesa e cuidados com o esposo e seus filhos,
        muitas vezes se mostram por demais arrogantes. No trato do lar e de suas
        coisas se tornam chatas porque querem as “coisas no seu devido lugar”
        chegando a beira do fanatismo se alguém tira alguma coisa do lugar onde
        ela colocou. Não raro os momentos em que demonstra falta de imaginação,
        principalmente quando repreendida, torna-se frágil e cai em prantos. Tem
        uma sutileza irritante para criticar os erros dos outros. Irritante,
        pois logo em seguida se propõe em ajudar, mesmo não confiando nas
        pessoas. Como mãe e pelo excesso de zelo para com os filhos, torna-se
        possessiva, mantendo seus filhos sob um domínio, muitas vezes cruel,
        querendo dominar a personalidade dos filhos, querendo que estes ajam de
        acordo como elas querem e pensam, quando assim não acontece, se tornam
        más, cruéis, ao ponto de perde-los. E quando isso acontece, se
        transformam em vítimas, fazendo chantagens emocionais até conseguir a
        volta deste ou destes filhos. Até pela Astrologia (que não é nossa
        praia) o planeta regente será Vênus (para os romanos). Afrodite para os
        gregos. Ela é a deusa do amor, do erotismo e da beleza. Conta a lenda
        que ela foi gerada pela ESPUMA DO MAR e surgiu dentro de UMA CONCHA DE
        PÉROLA. Simples coincidência? Não. Cito isso porque nossos irmãos ainda
        deverão ouvir pela mídia central, que será outro Orixá, o que
        evidentemente não será o correto. O ano terá acontecimentos marcantes,
        como: Amor, Fertilidade, harmonia, fartura. A energia do romantismo
        estará no ar. Aquelas pessoas que ainda estão sós, que ainda não
        encontraram o seu par ideal, encontrarão neste ano de 2.010. Exatamente
        a pessoa que compartilhará sentimentos, sonhos. Os relacionamentos em
        geral serão mais harmoniosos. O número de casamentos deverá aumentar
        consideravelmente. Mulheres que tem enfrentado dificuldades para
        engravidarem terão neste ano a grande oportunidade de gerarem filhos,
        onde algumas mulheres com até 52 anos de idade poderão engravidar.
        Haverá mais estabilidade emocional e financeira, pois estes ideais irão
        acompanhar o ser humano. A produção de alimentos no mundo todo estará em
        franca ascensão, o que fará com que gêneros alimentícios tenham seus
        preços reduzidos ou pelo menos manterem-se em níveis baixos. O
        espiritualismo, a moda, tratamentos estéticos, atividades artísticas,
        comércio de imóveis para casa própria, a pesca, frutos do mar, processos
        legais, todos os precatórios, federais, estaduais e municipais serão
        pagos, pois Yemanjá também é Justiça e Equilíbrio. Todos estes
        seguimentos estarão em franca expansão. O Direito será uma profissão
        muito prestigiada. A cura e até mesmo a erradicação do câncer de mama (o
        que acontecerá definitivamente no ano de Oxossi) a medicina terá um
        avanço gigantesco e surpreendente. Os conflitos mundiais terão um aliado
        enorme que é a diplomacia, pois esta estará em alta. Acontecimentos
        negativos para o ano: A humanidade mais uma vez aprenderá a respeitar a
        natureza, pois tudo indica que o Mar poderá se revoltar novamente e
        teremos então outro tsunami. Muitos acidentes nas águas, doce e salgada.
        E como ela só protege nas águas, acidentes enormes em terra acontecerão,
        pela imprudência e falta de bom senso dos homens. Muitos “mistérios” que
        ainda habitam as profundezas do mar surgirão à tona, o que nos dará mais
        certeza de que será do mar que os humanos terão, um dia, sua maior fonte
        de alimentos. Doenças mais acentuadas no ano de Yemanjá: Artrite,
        artrose, osteoporose, próstata, pulmão, varizes e trombose. Para se
        evitar que acontecimentos negativos cheguem a nossa porta, faça todo o
        possível para agradar a Yemanjá: No dia 31 de dezembro/09, vista uma
        roupa na cor azul claro e/ou branca. Pode ser roupa íntima, ou pelo
        menos porte alguma coisa, uma fitinha, por exemplo, na cor azul claro.
        Para os mais arrojados e com fé, acenda uma vela azul claro, pode ser de
        7 dias, e faça com que esta vela esteja acesa, a Zero Hora do dia 1º de
        janeiro de 2.010. Faça seus pedidos. Lembrem-se os Orixás não são
        Divinos, são Divinizados e por isso que eles precisam do agrado para
        atender os seus pedidos. No Reino de Juna Bomy, sito a Rua Dom Daniel,
        200 Esq. Rua Apiuna – Vila Real, Balneário Camboriú/SC, dia 26.12 as
        21h00min haverá o grande ritual de AGRADECIMENTO DO ANO. Participe!
      </p>
    ),
  },
  {
    year: "2009",
    title: "Ogum",
    img: ogum,
    text: (
      <p>
        Pela Tábua Cronológica dos Orixás da Nação de Cambinda, que usamos há 40
        anos, o ano de 2009 será regido por OGUM – Senhor da Guerra, Vencedor de
        Demandas . Dia da Semana é Quinta-Feira (Dia que começa o ano de 2009),
        Cor: Vermelho, Instrumentos Símbolos: O ferro, o aço e a enxada. Símbolo
        Cabalístico: A Espada. Será um ano nervoso, conflitos perigosos em todos
        os setores da humanidade, em casa, no trabalho, nas ruas, enfim em todos
        os lugares. Será necessário, todos pensarem duas vezes antes de tomarem
        atitudes e decisões explosivas. Pois este sentimento de irritabilidade
        estará no ar. Será necessária muita paciência, muita oração, muito amor.
        No campo financeiro será um ano de muitas dificuldades, pois Ogum não
        entende de dinheiro. De trabalho sim, de dinheiro não. Os setores de
        maiores dificuldades serão as indústrias de automóvel e da construção
        civil. Isso porque Ogum é Defensor dos Oprimidos e os lucros nestas duas
        atividades são exorbitantes, fora de qualquer realidade. Os preços
        destas duas atividades econômicas irão baixar de uma maneira
        surpreendente. O que sem dúvidas acarretará desempregos, pois temos a
        consciência de que um número extraordinário de empregos está nestes dois
        seguimentos. Será um ano perigoso para grandes empreendimentos, pois
        estaremos correndo grande risco com a volta da inflação. O governo mais
        uma vez terá que encontrar instrumentos fortíssimos para evitar uma
        inflação devoradora. Aumentos salariais, em todos os sentidos e
        atividades, encontrarão inúmeras dificuldades, até mesmo o Salário
        Mínimo estará correndo riscos. A sombra escura do desemprego estará
        pairando no ar. Pode até parecer um paradoxo, mas as Profissões que
        estarão altamente valorizadas: Agronomia – Aeronáutica – Exército –
        Marinha – Polícia Civil e Militar – Engenharia de Produção e Engenharia
        Metalúrgica – Fatores Econômicos promissores: Indústria de
        Entretenimento, Material Bélico, Indústria da Moda e da Beleza.
        Agricultura, Agropecuária e Petróleo. Será ano de grãos, principalmente
        no Brasil. COISAS RUÍNS DO ANO: Além das dificuldades que enfrentarão as
        Indústrias de Automóvel e a Construção Civil, (repito que isso irá
        acontecer pelos lucros atuais são desproporcionais), será um ano de
        muitos conflitos, altamente perigoso para a América do Sul. Nervosismo à
        flor da pele, que vamos sentir no nosso dia a dia. Temos que ter muita
        cautela com o tal de “pavio curto”, para não se arrepender amargamente
        depois. A YANSÃ (Oyá) Orixá dos Ventos, chuvas, tempestades, terremotos
        e raios, estarão no 2º plano e estaremos sujeitos a tudo isso. No campo
        das doenças, o mal estará localizado no estômago, intestino e fígado. O
        estresse acarretará muita violência. Portanto, todo cuidado, paciência e
        compreensão de todos, será pouco.
      </p>
    ),
  },
];
