import React from "react";
import Meet from "../components/meet";
import oxala from "../components/images/oxala.jpeg";
import iansa from "../components/images/iansa.jpeg";
import iemanja from "../components/images/iemanja.jpeg";
import ogum from "../components/images/ogum.jpeg";
import oxossi from "../components/images/oxossi.jpeg";
import oxum from "../components/images/oxum.jpeg";
import xango from "../components/images/xango.jpeg";

export const orixas = [
  { name: "Conheça seu Orixá", text: <Meet /> },
  {
    name: "Oxalá",
    img: oxala,
    infos: (
      <p>
        O maior de todos os Orixás
        <br />
        <br />
        Sincretismo: JESUS DE NAZARETH
        <br />
        Dia da Semana: DOMINGO
        <br />
        Cor: BRANCA
        <br />
        Regente da PAZ, SORRISO E TRANQUILIDADE
        <br />
        Saudação: “Êpa-Bábá” "Epaô-Bábá”
      </p>
    ),
    text: (
      <p>
        O filho de Oxalá, tem temperamento audacioso e independente. Jamais se
        detém para ouvir opiniões alheias. Lembramos que são três falanges de
        Oxalá as mais conhecidas: Oxalá Guiã, ou OXAGUIÃ: Jovem e Guerreiro.
        Oxalá Lufã ou OXALUFÃ: Velho e Sábio, e Oxalá URUMILÁIA: Paz, Sorriso e
        Tranquilidade. Isto para determinar falanges, pois OXALÁ é um só, a
        vibração original é uma só, a energia é uma só e responde por todos os
        três juntos.
        <br />
        No culto de Angola, chama-se LEMBA. Filhos de Oxalá tem muita
        dificuldades no lado do amor, pois em virtude de ser o maior de todos os
        Orixás, está acima dos demais, possuidor de uma invejável inteligência e
        românticos extremosos. Através de sua falsa humildade e aparente
        timidez, são sedutores por excelência. Por isso que o casamento de um
        filho de Oxalá só será perfeito se realizado com uma Filha de Yemanjá,
        pois verão que suas virtudes e defeitos se assemelham em muito. Com as
        filhas de Yemanjá são fiéis, porém se casados com filhas de Yansã ou
        Oxum, são infiéis e traidores.
        <br />
        Algumas lendas ou Nações dão Oxalá como um orixá feminino ou pelo menos
        andróginos, por isso que a grande maioria dos filhos de Oxalá tem em sua
        aparência “um homem bonito” e tratam as outras pessoas com delicadeza.
        <br />
        Seu falar é manso, seus movimentos são lentos. Prestativos para com
        todos, jamais se nega em dar auxilio a qualquer pessoa e nunca diz que
        ”não sabe” sem pelo menos tentar. Tem tendências fortíssimas para criar
        e inventar. Gosta de viver mais ou menos isolado.
        <br />
        Sua tranquilidade e dignidade lhes impede de cometer atos desonestos e
        cada vez que isso acontece se dá mal. Detesta pedir ajuda aos outros e
        não gosta de ser ajudado.
        <br />
        Para desenvolver mediúnicamente um filho de Oxalá é uma tarefa nada
        fácil. Primeiro é preciso recorrer ao seu segundo Plano para abrir a sua
        aura e este seu segundo guia espiritual é quem irá trabalhar nos
        terreiros, pois Oxalá mesmo, em virtude da “lentidão” e “morosidade”
        como se manifesta esta vibração, é difícil “trabalhar” com o médium.
        Usa-se esta vibração, neste mesmo médium, somente em trabalhos
        especiais.
        <br />
        Uma das características do filho de Oxalá que dificulta seu
        desenvolvimento é sua irritante timidez e a dificuldade que tem em
        permanecer em locais com muitas pessoas. É um forte observador e juiz de
        si mesmo, pois tem consciência dos seus erros. Dificilmente tem inimigos
        e muito raramente se mete em encrencas.
        <br />
        Jamais disfarça no amor e nem em suas emoções. Tem uma paciência
        incrível e metódico em tudo que faz, sempre uma coisa de cada vez.
        Devota um amor e respeito pela sua mãe, inacreditável. Sua parte
        vulnerável e mais fraca é a cabeça onde tem distúrbios de visão, rinite
        e sinusite.
        <br />
        Seu sistema nervoso é delicado, pois aparentemente são tranquilos , mas
        interiormente são explosivos e por isso buscam ficar isolados e repouso
        no sono, onde repõem energias. São discretos e de pouca fala . Procuram
        mais ouvir do que emitir opiniões. Sistemáticos em tudo mesmo lhes
        faltando astúcia, pois na maioria das vezes se apresentam e se mostram
        como ingênuos, o que na verdade não são.
        <br />
        Introspectivos e silenciosos. Quando novos, criança e fase adolescente,
        não gostam de estudar, pois lhes parece tudo enfadonho em virtude do
        grande e elevado grau de inteligência. Filhos de Oxalá não nasceram para
        serem empregados de ninguém, por isso que como servidores mandados, não
        são dos melhores, porém quando chegam ao poder de mando ou que tenham
        seus próprios negócios (proprietários) se dão muito bem, apesarem de
        serem extraordinários planejadores e péssimos executores.
        <br />
        Sabem como ninguém, criar e planejar todo e qualquer tipo de negócio e
        por ser do seu tipo de agir, já divisam o produto final do seu projeto,
        esquecendo de que por em prática o que criou é uma etapa importante que
        muitas vezes ele não tem talento para acompanhar esta execução. Por isso
        que tudo que for criado por um filho de Oxalá merece crédito, porém deve
        se acercar de um filho de Ogum ou Xangô, dependendo do empreendimento,
        para “executar” a tarefa. Cada vez que um filho de Oxalá tenta “tocar”
        sozinho qualquer projeto seu, o resultado custa a aparecer o que lhe
        aborrece, abandonando tudo pela metade. Quando jovem entra naquela fase
        que todo pai diz “meu filho não sabe o que quer. Começa tudo e não acaba
        nada”. Procure fazer sua numerologia que constatará ser filho de Oxalá.
        Não sendo, o problema passa a ser espiritual, reencarnacional.
        <br />
        Procure um bom terreiro, um bom médium que este prestará socorro ao
        problema do seu filho. Lembrem-se de que afirmamos que os Orixás não são
        Divinos. São sim, Divinizados ou Divindades. Tanto é que a definição
        mais antiga do que é Orixá, é a seguinte: “Orixá não é espírito porque
        come e bebe como homem e não é homem porque voa como espírito”. São
        portanto, Devas da Natureza e que regem tudo o que acontece aqui na
        terra, começando por seus próprios filhos de que são regentes. Este
        trabalho se destina a ensinar como “driblar” suas dificuldades no
        momento em que se sabe quem é o Orixá regente e não para apontar os
        defeitos dos outros, como se corrigirem para viverem melhor e com maior
        harmonia consigo mesmo.
      </p>
    ),
  },
  {
    name: "Yemanjá",
    infos: (
      <p>
        Mãe de todas as Mães
        <br />
        <br />
        Sincretismo: NOSSA SENHORA DOS NAVEGANTES
        <br />
        Dia da Semana: SEXTA-FEIRA
        <br />
        Cor: AZUL CLARO (Celeste)
        <br />
        Regente da Água Salgada, Senhora dos Mares, Símbolo da Fertilidade
        <br />
        Saudação: “Ômio”- “Odôcy-Ába” - “Odô-Yá”.
      </p>
    ),
    img: iemanja,
    text: (
      <p>
        Na Umbanda e hoje em algumas Casas de Candomblé, é único Orixá que tem
        sua imagem própria nos Altares, dispensando o sincretismo como acontece
        com os demais Orixás: Uma bela Mulher, saindo das águas do Mar, com
        vestimenta em Azul Claro, com o Colo Nu, espargindo estrelas de ambas as
        mãos. Na África e em Cuba, ela é representada nas imagens com o aspecto
        de uma matrona, de seios volumosos, símbolo de maternidade fecunda. As
        filhas de Yemanjá são férteis, caso encontrar alguma mulher que possue
        esta numerologia e não tiver filhos, pode ter certeza de que não é
        clinico seu problema.
        <br />
        As filhas de Yemanjá Kayala (numerologia 27) e as filhas de Yemanjá
        Ogunté ou Okunté (numerologia 29), tem dificuldades de engravidar, porém
        terão no mínimo um filho e jamais conseguirão passar de dois. As filhas
        de Yemanjá Ogunté e Kayala, carregam consigo as características de seus
        Santos. São rancorosas, severas e violentas, confundido-se muitas vezes
        com filhas de Yansã. Mesmo sabendo que na realidade o ORIXÁ YEMANJÁ é um
        só, porém suas irradiações, virtudes e defeitos, dividem-se em sete (7).
        <br />
        Por exemplo: As filhas de Yemanjá Sobah ou Asaba, tem o olhar
        insustentável. São altivas e escutam apenas virando-se de costas ou
        inclinando-se ligeiramente de perfil, são perigosas e voluntariosas. As
        filhas de Yemanjá Marabô ou Maylewo, são tímidas e reservadas.
        <br />
        Filhas de Yemanjá JANAÍNA (as que mais se manifestam nos terreiros de
        Umbanda), geralmente são altas, bonitas, morenas, lábios grossos. São
        gentis e compassivas, perdoam facilmente as ofensas que lhes dirigem.
        Extremamentes apegadas a casa, marido e filhos. Honesta, esposa ideal se
        casada com filho de Oxalá. Tem hábitos simples, respeito as tradições e
        extremamente pontual. Dotada de um coração humanitário, torce e ajuda
        aos sucessos de outros. Não é dada a experiências sexuais antes do
        casamento. Muito vaidosa com os cabelos.
        <br />
        Repetimos que as filhas de Janaína são as únicas de estatura média para
        alta. As demais são medianas para baixa. A maioria são mulheres bonitas,
        de pouca estatura, com os olhos e lábios bem marcantes.
        <br />
        As diversas denominações de Yemanjá são: Ogunté, Kayala, Sobah, Janaína,
        Mãe Dandá, Dandalunda, Marabô, Mukunã, Inaê e Kaja. RAINHA DO MAR é a
        denominação mais tradicional, nos meios Umbandistas. Na mitologia ,
        Yemoja, ou Yemanjá seria filha de Olóòkun ou Olokun, que é o mesmo que
        Deus ou Deusa do Mar, o que tornou Yemanjá em Senhora dos Mares.
        <br />
        Na mistura da mitologia, aparece a da Sereia Européia com seu canto
        mágico, a Lorelai, loura de olhos azuis, metade peixe, metade mulher e o
        seu canto fatal, quem lhe ouvir cantar “vai com ela pro fundo do mar”
        Além dessas características já registradas, não poderíamos deixar de
        transcrever a descrição do arquétipo das filhas de Yemanjá feita por
        Lydia Cabrera, que com exceção da parte grifada em negrito, na qual
        temos nossas dúvidas, achamos por bem e até para conhecimento de nossos
        leitores que não tiveram acesso a grande obra desta nobre e conceituada
        escritora:
        <br />
        “As filhas de Yemanjá são voluntariosas, fortes, rigorosas, protetoras,
        altivas e algumas vezes, impetuosas e arrogantes; tem sentido da
        hierarquia, fazem-se respeitar e são justas mas formais; põem à prova as
        amizades que lhe são devotadas, custam muito a perdoar uma ofensa e, se
        perdoam não a esquecem jamais. Preocupam-se com os outros, são maternais
        e sérias. Sem possuírem a vaidade de Oxum, gostam do luxo, das fazendas
        azuis e vistosas, das jóias caras. Elas tem tendência à vida suntuosa
        mesmo se as possibilidades do cotidiano não lhes permitem um tal
        fausto”.
      </p>
    ),
  },
  {
    name: "Oxum",
    infos: (
      <p>
        Senhora das Águas Doces
        <br />
        <br />
        Sincretismo: NOSSA SENHORA APARECIDA
        <br />
        Dia da Semana: SÁBADO
        <br />
        Cor: AMARELA
        <br />
        Regente do Amor, da Bondade e do Ouro. Rainha da Beleza, Senhora dos
        Rios, Lagos, Açudes e Cachoeiras.
        <br />
        Saudação: "Ayê Yêu" - "Yê Yêw" - "Ora Minha OXUM - "ÒSUN".
      </p>
    ),
    img: oxum,
    text: (
      <p>
        É A GRANDE Deusa e Rainha de todos os rios, com grandes poderes sobre a
        água doce, sem esta não haveria vida sobre a terra. As filhas de Oxum,
        são vaidosas, algumas ao extremo, como as Pandás, pois "limpam suas
        jóias, antes limparem seus filhos".
        <br />
        Vamos esclarecer logo, sobre certas diferenças que existe nas
        características de uma filha de Oxum para outra. Em nossos estudos e
        exaustivas pesquisas, chegamos a conclusão de que as filhas de Oxum Dokô
        (Yéyé Odò), cuja numerologia é 30, são as que carregam com mais
        propriedade as características de todas as Oxuns, suas virtudes e seus
        defeitos.
        <br />
        As filhas de Oxum Pandá (Òsun Apará), cuja numerologia é 31, é a mais
        jovem. Esta sim é faceira, tem graça e beleza. Coquete. Gosta de jóias e
        de música. Gostam de perfumes e roupas caras, são elegantes.
        <br />
        As filhas de Oxum Nanã (Ósun Àyalá ou Iyánlá), cuja numerologia é 32, é
        a Oxum "Velha", já tem particularidades diferentes e que chamam a
        atenção. São um tanto reservadas, tanto no vestir como falar e agir.
        Sofisticadas e "rabugentas". Com pensamentos e ações de pessoas com
        idade avançada. Demonstram mais inteligência que as outras independente
        da idade de suas filhas de cabeça. Não gostam de ter muitos filhos,
        porém gostam de cuidar e zelar pelos filhos dos outros.
        <br />
        Nesta numerologia (32) encontra-se também as OXUM MARÉ (Meio homem/Meio
        Mulher). As mulheres com esta numerologia encontram muitas dificuldades
        no campo sentimental. As filhas de OXUM DEMW (Demú) - (Ósun Ijúmú), cuja
        numerologia é 33, relacionada com demônios e bruxas. Pelo seu caráter e
        temperamento forte. Guerreira e poderosa. Voluptuosas e sensuais. Sua
        aparência graciosa e sedutora, escondem uma vontade muito forte de
        ascensão social sem medir consequências. Elas tem muito de Yansã/Oyá.
        Notem portanto que existe uma grande diferença entre as filhas de Oxum.
        Algumas até gritantes. Por isso que quando se falar em Filha de Oxum há
        que se levar em conta a numerologia, ou seja, a que Oxum pertence tal
        filha.
        <br />
        Se faz também necessário esclarecermos sobre o sincretismo que relaciona
        Oxum. Para Oxum Dokô (Yéyé Odó) o sincretismo perfeito é Nossa Senhora
        Aparecida e para Oxum Pandá (Ósun Apará) é Nossa Senhora da Conceição.
        Só assim podemos entender porque em algumas regiões se diferem os
        sincretismos.
        <br />
        Achamos por bem em registrar de que em algumas cidades da Bahia a Oxum é
        sincretizada com Nossa Senhora das Candeias e no Norte com Nossa Senhora
        dos Prazeres.
        <br />
        Porém um detalhe nos chama atenção, em todo o Brasil a data consagrada
        para Oxum é dia 08 de dezembro e todos nós sabemos que este é o dia de
        NOSSA SENHORA DA CONCEIÇÃO. Outro predicado da filha de Oxum é a eterna
        aparência jovem. Parece não envelhecerem nunca. O parceiro ideal para a
        filha de Oxum é sem dúvidas nenhuma o Filho de Xangô, pois seus defeitos
        para a Oxum se tornam virtudes.
      </p>
    ),
  },
  {
    name: "Ogum",
    infos: (
      <p>
        Senhor da Guerra
        <br />
        <br />
        Sincretismo: SÃO JORGE
        <br />
        Dia da Semana: QUINTA-FEIRA
        <br />
        Cor: VERMELHA
        <br />
        Regente do Ferro e do Aço Defensor dos Oprimidos Ogum, Senhor da Guerra,
        vencedor de demandas - Símbolo dos Guerreiros.
      </p>
    ),
    img: ogum,
    text: (
      <p>
        Seus filhos se definem na soma dos números de sua data de nascimento,
        cuja soma venha dar: 21, 22, 23, 24, 25, 27 e 29 (isto quer dizer que de
        21 a 29, com exceção de 26 e 28, são filhos de Ogum). Os filhos de Ogum,
        são alvissareiros e belicosos como o próprio Orixá. Arrojados, metidos e
        muitas vezes bravos e exageradamente briguentos. Não suportam a força em
        todos os seus sentidos esmagar os indefesos. São defensores natos dos
        oprimidos. Rápidos de raciocínios e descuidados em emitir suas opiniões,
        pois não escolhe lugar nem hora para dizer a verdade. São daqueles que
        usam muito a expressão “doa em quem doer” e pela sua imponência e muitas
        vezes arrogantes, dói nele primeiro.
        <br />
        Fala, quando bravo, sem medir as palavras. Autoconfiante, enérgico e
        possessivo. Reage com extrema rapidez aos impulsos o que geralmente lhe
        leva a situações constrangedoras e perigosas. Para definir bem a
        impulsividade dos filhos Ogum, nos dissemos que se alguém lhe bate à
        porta, estando ele pelo lado de dentro, não pergunta quem é, ele abre a
        porta, para ver quem é. Egocêntrico natural, gosta de receber elogios,
        bem como não economiza elogios para tudo aquilo ou aqueles que ele
        gostar e achar que está certo. Tem um excelente faro para arquitetar as
        coisas e colocar dinamismo nas palavras e nas ações.
        <br />
        Tem facilidades em fazer amigos, gosta de estar rodeado deles. Sendo
        dono de seu próprio negócio, de uma empresa, tem dificuldades em mandar,
        ordenar, em funcionárias mulheres. É tão rápido em suas análises sobre
        tudo que imediatamente fala aos que estão por perto. Exterioriza uma
        autoconfiança invejável, bem como uma agressividade notória, mas no seu
        íntimo é um conservador, retórico, sem ser chato. Um filho de Ogum, tem
        que ser muito bem doutrinado, para que possa conter os seus defeitos,
        que são basicamente a fúria e o ódio. Coisas que geralmente lhe leva a
        destruição de si mesmo. São pessoas violentas, briguentas e impulsivas.
        <br />
        Encontram dificuldades imensas para perdoarem as ofensas que foram
        vítimas. Perseguem com obstinação e determinação seus objetivos e jamais
        se desencorajam facilmente. Os filhos de Ogum triunfam naqueles momentos
        em que qualquer outro teria abandonado o combate e perdido a esperança.
        Impõem um grande fascínio, tem facilidades de atrair o sexo oposto pela
        sua beleza. São por demais sinceros e francos em suas intenções e talvez
        por isso, tornam-se difíceis de serem odiadas. Não medem esforços para
        preservarem um casamento, pois os filhos de Ogum não tem estrutura
        psicológica para enfrentar uma separação e conquistar uma outra pessoa,
        tarefa muito trabalhosa para ele.
        <br />
        Possuem um grande senso de responsabilidade e dedicação à família,
        fazendo grandes sacrifícios para que nada falte aos que lhe rodeiam. Os
        sete Oguns que dão suas características a seus filhos são: OGUM
        BEIRA-MAR (O que rege as águas salgadas) - OGUM MEGÊ ( O que rege as
        estradas e os caminhos do Cemitério) - OGUM ANARUÊ (O que rege a terra,
        fertilidade da terra, protetor dos agricultores) - OGUM YARA (O Ogum das
        águas doces) - OGUM ROMPE-MATO (O que rege as matas) - OGUM DILÊ (O que
        rege e vibra nas doenças. Muito usado nos trabalhos de Oriente na
        Umbanda) - OGUM MATINATA (O que rege á noite. Madrugadas e tudo que se
        relacione com a noite e a lua).
        <br />
        Também tem OGUM DE MALÊI, OGUM DE NAGÔ e OGUM SETE ESPADAS, que vibram
        nas faixas dos anteriormente descritos. Finalizando as características
        dos filhos de Ogum, registramos que são aquelas pessoas impetuosas e
        arrogantes, daquelas que se arriscam a melindrar os outros por uma certa
        falta de discrição quando alguém lhe presta algum serviço, porém devido
        a sua sinceridade e franqueza nas suas decisões e intenções, tornam-se
        difíceis de serem odiadas.
      </p>
    ),
  },
  {
    name: "Xangô",
    infos: (
      <p>
        Senhor do Fogo, da Justiça e do Trovão
        <br />
        <br />
        Sincretismo: SÃO JERÔNIMO
        <br />
        Dia da Semana: TERÇA-FEIRA
        <br />
        Cor: MARROM
        <br />
        Regente das Pedreiras, da Justiça, do Fogo e do Trovão. Os filhos de
        Xangô, são de um caráter violento e imperioso.
      </p>
    ),
    img: xango,
    text: (
      <p>
        Viril e atrevido. Justiceiro por excelência. Castiga os mentirosos,
        ladrões e malfeitores. A mitologia conta que Xangô teve três esposas:
        Oyá (a guerreira de temperamento explosivo), Oxum (dócil, faceira e
        bela) e Obá (a fiel companheira prestativa e extremamente amorosa).
        Mesmo que no conceito Umbandista se diga que Xangô é das Pedreiras, as
        vezes dizendo-se somente das pedreiras, não reside a verdade pura. Ele
        rege as pedreiras, só isso, mas não reside somente ali. Mesmo que o
        melhor local para se depositar uma oferenda pra Xangô seja a Pedreira:
        No cume, ao meio ou aos pés dela.
        <br />
        Todos que se sentirem injustiçados devem recorrer a Xangô, pois na
        dureza da pedra, simboliza a inflexibilidade da Lei e da Justiça. É uma
        força enorme que de uma forma sensata e justa, dá a quem é devido o que
        lhe é de direito. Sua importância na Umbanda é tão grande e forte, que
        chega a denominar uma Religião. No Nordeste, mais precisamente em
        Pernambuco, existe a Religião de Xangô. Os xangozeiros. O seu mantra no
        atabaque é um dos mais bonitos e difíceis para um Alabê, cujo toque
        chama-se Alujá.
        <br />
        Contam os antigos Candomblesistas, que quando dois Pais-de-Santo se
        digladiavam por algumas diferenças que poderiam surgir, mediam suas
        forças, através de seus alabês (tocadores de atabaque) que iniciavam a
        disputa no tóque do Alujá, onde só terminava quando um dos alabês viesse
        a morrer. Por isso que o Alujá, é considerado como o tóque da morte. Os
        filhos de Xangô sabem o que querem e é muito difícil de serem enganados.
        Tem suas opiniões próprias, irredutível nos seus argumentos e acha
        sempre que está com a razão.
        <br />
        Precipitado nas suas ações e faz de tudo para por em descrédito seus
        inimigos. Não tem por método em confiar nos outros. Em virtude de ser
        extremamente cuidadoso em tudo que faz e principalmente no que vê, pois
        tem o hábito de anotar tudo, exigente com as coisas certas, conforme sua
        ótica, são os que causam os maiores problemas dentro dos terreiros pois
        não admitem erros de ninguém. Não perdoam nem mesmo os Pais ou Mães de
        Terreiro, que muitas vezes tem que relevar certas coisas de seus filhos,
        causando com isso constrangimentos e aborrecimentos dentro dos
        terreiros.
        <br />
        No lar ou em seu ambiente de trabalho, consegue respeito, porém consegue
        também muita indignação, por ser considerado chato, pois quer tudo
        sempre em seu devido lugar, não admitindo que alguém mecha em qualquer
        coisa e objeto que ele tenha colocado ali. Tem uma facilidade muito
        grande no que diz respeito ao sexo feminino. Em virtude de ser
        galanteador. Porém se casar com a filha de Yemanjá, se tornará frio e
        traidor. Jamais dará certo um casamento desses, é separação na certa.
        Para salvar um casamento desses, Xangô com Yemanjá, este casal corre o
        risco de nascer deles, um filho homem que tenha como seu Orixá Maior,
        Oxalá, ou uma filha mulher que tenha como seu Orixá Maior, Oxum. Mesmo
        assim, será um relacionamento frio, sem amor.
        <br />
        Com a filha de Yansã em virtude de ser egocêntrico, metódico e
        extremamente correto, causa estranheza e mal estar no casal, que
        fatalmente virá trazer aborrecimentos infindos. Com a filha de Oxum sim,
        este é o casamento absolutamente perfeito para o filho de Xangô, pois
        todos os seus defeitos para a filha de Oxum se tornam virtudes. Na
        vibração Original (Orixá XANGÔ), existem uma subdivisão, praticada mais
        no Candomblé, porém se faz necessário que os Chefes de Terreiros
        conheçam esta particularidade, pois assim saberão porque um filho de
        Xangô, muitas vezes se apresenta diferente de um outro, também de Xangô:
        <br />
        Xangô Agodô (Justiça); Xangô Aganjú (Vento); Xangô Alafin (Criança);
        Xangô Djakutá (Pedreira e Mata); Xangô Kaô (Saúde); Xangô Kamwkay
        (Fogo); Xangô Kambará - Xangô Aboní - Xangô Alufã e Xangô Baru.
        <br />
        Para qualquer destas vibrações ou Linhas como queiram a saudação é a
        mesma: Caô Cabiecile (Aportuguesado) ou ainda Caô Cabecile. Na grafia
        Yoruba é Káwòó Kabyesí. "Xangô esta chegando. Venham ver e admirar o
        Rei". O que dói nos ouvidos é chegar num terreiro e ouvir a saudação
        "Caô Cabecinha". Horrível! Ridículo!. Falta de conhecimento e o que é
        pior, soa como ofensa ao poderoso Orixá Xangô.
      </p>
    ),
  },
  {
    name: "Oxossi",
    infos: (
      <p>
        Senhor da Floresta Caçador de Almas
        <br />
        <br />
        Sincretismo: SÃO SEBASTIÃO
        <br />
        Dia da Semana: QUARTA-FEIRA
        <br />
        Cor: VERDE Regente das Matas, da Terapêutica do Espaço, Rei de Kêto.
      </p>
    ),
    img: oxossi,
    text: (
      <p>
        OXOSSI, Rei das Florestas - Caçador de Almas - Rei de Kêto - Rei das
        Matas, seus filhos se definem pela numerologia 26 e 28, ou seja , na
        soma dos números da data de nascimento. Os filhos de Oxossi, são
        desconfiados, fiéis, extremamente cuidadosos em tudo, preocupa-se muito
        com o dia de amanhã.
        <br />
        Bom amigo, calmo e explosivo: quando Calmo chega a irritar, quando
        Bravo, chega ir as raias da ignorância. Por estudos profundos, tanto os
        que fizemos como outros estudiosos fizeram, o filho de Oxossi traz um
        estigma que para muitos é ruim, porém é uma verdade incontestável:
        nasceram para serem solteiros.
        <br />
        Dentro do panteão, existem e esta é a verdade, 7 Orixás Maiores, são
        quatro Orixás Masculinos e três Femininos, sendo que os casais
        perfeitos, ou “quase” perfeitos, são Oxalá com Yemanjá, Ogum com Yansã e
        Xangô com Oxum, sendo o Oxossi o quarto Orixá masculino, não tem
        parceira, ou seja, pode casar com qualquer uma das três: Yemanjá, Oxum
        ou Yansã, porém não consegue fazer nenhuma feliz. E ele próprio, num
        certo tempo de casado, também se torna infeliz.
        <br />É claro, que depois de alguns revezes, tristezas e aborrecimentos,
        o filho de Oxossi, consegue amenizar pelo menos, as diferenças que se
        apresentam entre ele e a sua parceira e de uma forma ou de outra,
        consegue viver pelo menos em paz. Se casado com uma filha de Yansã, as
        brigas são tão constantes e sempre por culpa dele, que chegam ir as vias
        de fato. Se casado com uma filha de Yemanjá, o casamento é tão “frio”
        que um dos dois irá trair, quando não é os dois. Se casado com filha de
        Oxum, em virtude dela ser “carente”, pois é mansa e tem muito amor para
        dar, e ele , filho de Oxossi, “desligado” e possessivo, quase sempre
        será traído ou abandonado. Separação na certa.
        <br />
        Por isso que afirmamos o Filho de Oxossi, não nasceu para casar. O
        melhor mesmo é ser um “eterno amante”, nunca passar pelo ato sacramental
        do casamento, pois, pelo menos até agora e é necessário que me provem ao
        contrário, só assim poderá ser feliz e fazer uma mulher feliz.
        <br />
        No campo das amizades, os filhos de Oxossi, são os melhores amigos que
        alguém pode ter, pois são de absoluta confiança.
        <br />
        Os cultuadores de Oxóssi na África, estão em extinção, apenas um
        reduzidíssimo número de seguidores ainda o veneram. Porém no Brasil é
        bastante difundido, pois nos conta a história que a Umbanda nasceu e
        teve pelo menos de 1.908 até 1.915 aproximadamente, a cultuação única a
        falange de Oxossi, onde baixavam os espíritos de Índios, bem como dos
        Pretos-Velhos.
        <br />
        Não existe um único terreiro de Umbanda sequer, que não tenham seus
        Caboclos, Indios, Tupís-Guaranís, Tupinambás, Flecheiros, Águias de
        todas as cores e matizes, bem como os Penas de todas as cores e matizes.
        <br />
        Na Nação de Kêto, pelo Candomblé, cultuada no Brasil e em Cuba é que
        encontramos a veneração e respeito a Oxóssi, mesmo assim com a
        denominação Òsóòsi. Na África, o país de Kêto, foi completamente
        destruído e saqueado pelas tropas do rei do Daomé, no passado, e seus
        habitantes, principalmente os iniciados em Oxóssi, foram trazidos como
        escravos para o Brasil. Esses escravos africanos trouxeram consigo o
        conhecimento do ritual de celebração desse culto.
        <br />
        No culto de Nação Africana, ou candomblé de Kêto, é mais conhecido como
        “Odé” na realidade o nome certo sería Oré ou Orê. Que é um outro Orixá
        da caça entre os iorubás. Na mesma vibração, como parte feminina, tem-se
        Ossain ou Ossanha, como é mais difundido e falado. Orixá das folhas.
        <br />
        Os caboclos mais conhecidos e chamados nas Linhas de Umbanda são:
        Tupinambá, Águia Branca, Tupaíba, Tupiara, Flexa das Matas, Sete Fléxas,
        Pantera Negra, Pai Guiné, Caboclo Jaguané, Caboclo Rôxo, “Seu” Mata
        Virgem, Pena Dourada, Pena Roxa, Pena Amarela, Caboclo Tupi, e tantos
        outros.
        <br />
        Na vibração de JUREMA, este sim essencialmente brasileiro, que para
        muitos sería a vibração de Oxossi em seu lado feminino, porém como já
        dissemos, JUREMA não é Orixá, portanto não pode ser Mãe de Cabeça de
        ninguém. Cabocla Jurema é ameríndia. Podemos dizer “é coisa nossa”.
        Também não existe terreiro de Umbanda, que não tenha em suas fileiras,
        médiuns que recebam as vibrações de Jurema.
      </p>
    ),
  },
  {
    name: "Yansã",
    infos: (
      <p>
        Senhora dos Ventos e da Chuva
        <br />
        <br />
        Sincretismo: SANTA BÁRBARA
        <br />
        Dia da Semana: QUARTA-FEIRA
        <br />
        Cor: AZULÃO
        <br />
        Regente das Chuvas, Ventos, Tempestades e Terremotos. Também conhecida
        por Oyá.
        <br />
        Saudação: "Eparrêy" - "Epayê-yô".
      </p>
    ),
    img: iansa,
    text: (
      <p>
        As filhas de Yansã são dotadas de uma sensualidade desenfreada.
        Temperamento ardente, impetuoso e autoritário. Arrojada, guerreira
        avassaladora. Dona de paixões ardentes e que preserva o que é seu,
        principalmente seus homens, seus amores.
        <br />
        Em uma de suas lendas, diz bem o que é Oyá-Yansã, a esposa guerreira de
        Ogum: "Ogum foi caçar na floresta. Colocando-se à espreita, percebeu um
        búfalo que vinha em sua direção. Preparava-se para matá-lo quando o
        animal, parando subitamente, retirou sua pele. Uma linda mulher apareceu
        diante de seus olhos. Era Oyá-Yansã. Ela escondeu a pele num formigueiro
        e dirigiu-se ao mercado da cidade vizinha. Ogum apossou-se do despojo,
        escondendo-o no fundo de um depósito de milho, ao lado de sua casa, indo
        ao mercado para cortejar a mulher-búfalo.
        <br />
        Ele chegou a pedi-la em casamento, mas Oyá recusou inicialmente.
        Entretanto, ela acabou aceitando, quando, de volta a floresta, não mais
        achou a sua pele. Oyá recomendou ao caçador não contar para ninguém de
        que ela era um animal. Viveram bem durante alguns anos. Ela teve nove
        crianças, o que provocou ciúmes nas outras esposas de Ogum. Porém, estas
        conseguiram descobrir o segredo da aparição da nova mulher. Logo que o
        marido se ausentou, elas começaram a cantar 'Você pode beber, comer e
        exibir sua beleza. Mas sua pele está no depósito, você é um animal'. Oyá
        compreendeu a alusão; encontrando sua pele, vestiu-a e voltando à forma
        de búfalo, matou as mulheres ciumentas.
        <br />
        Em seguida, deixou seus chifres com os filhos, dizendo-lhes: 'Em caso de
        necessidade, batam um contra o outro e eu virei imediatamente em vosso
        socorro'. É por essa razão que chifres de búfalos são sempre colocados
        nos locais consagrados a Oyá-Yansã." Sua força e garra, bem como sua
        coragem, pode-se notar nos oríkìs, que lhe são dirigidos:
        <br />
        "Oyá, mulher corajosa que ao acordar, empunhou um sabre. Oyá, mulher de
        Xangô. Oyá, cujo marido é vermelho. Oyá, que embeleza seus pés com pó
        vermelho. Oyá, que morre corajosamente com seu marido. Oyá, vento da
        morte. Oyá, ventania que Balança as folhas das árvores por toda parte.
        Oyá, a única que pode segurar os chifres de um búfalo".
        <br />
        Mesmo sabendo que vamos encontrar algumas resistências por parte de
        outros seguidores e estudiosos de Umbanda, as cores de Yansã/Oyá é
        AZULÃO. Isto porque como já dissemos no inicio deste trabalho, que não é
        possível aceitar que um Orixá tenha ou use a cor do outro em virtude da
        elevada energia da magia das cores. Azulão é a cor que fica estampada em
        dias de tempestades e chuvas fortes.
        <br />
        Quando incorporada em uma de suas filhas, ou em qualquer médium, a
        vibração de Yansã traz adorno de cabeça tipo coroa, com franjas de
        miçangas azulão. Outro "axé" (força/energia), para as filhas de Yansã
        usarem é uma capa azulão, de qualquer tecido, que pode ir até a cintura
        ou mais. Suas danças são guerreiras. Ela lembra também, através de seus
        movimentos sinuosos e rápidos, numa gira espetacular esvoaçando sua capa
        azulão, as tempestades e os ventos enfurecidos.
        <br />
        Se faz necessário, registrarmos neste trabalho algumas Yansãns/Oyás, que
        em virtude de suas regências, trazem características diferentes as suas
        filhas: Yansã de Balé (Yànsán de Igbalé) - A dona dos Egúns (espíritos
        de mortos). Tem regência sobre o fogo (As Salamandras); Yansã Matamba -
        Mãe da Guerra - Defensora dos inocentes que sofrem por vingança. Quem
        faz aparecer a Luz na Justiça.
        <br />
        Rege junto com Oyá Oloxá, os ventos. Oyá Odára - A mais bonita delas.
        Oyá Funã - A Yansã Menina. A jovem tentadora pela sua beleza rude.
        Diferente. Que tem muito amor, porém ciumenta ao extremo e pode trair e
        continuar com ciúmes do marido traído. Oyá Oloxá - ou Odó Oyá - A Yansã
        velha. Oyá perfeita. Regente dos Ventos e Tempestades.
        <br />
        Apesar de todos os esforços que fizemos, estudando, pesquisando, não nos
        foi possível distinguir a numerologia específica de cada uma dessas,
        como fizemos com a Oxum. As filhas de Yansã pesquisadas por nós, quando
        achávamos que pertencia a tal numerologia, estas nos surpreendiam com
        suas atitudes que nos colocavam inúmeras dúvidas.
      </p>
    ),
  },
];
