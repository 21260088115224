import React from "react";
import arrowLeft from "../components/assets/arrow-left.svg";
import arrowRight from "../components/assets/arrow-right.svg";

const ImageViewer = (images) => {
  const [open, setOpen] = React.useState(false);
  const [idx, setIdx] = React.useState(0);
  const [image, setImage] = React.useState();

  const handleImage = (i) => {
    setIdx(i);
    setImage(images.img[i]);
    setOpen(true);
  };

  const RenderImages = () => {
    return images.img.map((i, index) => {
      return (
        <img
          className="thumb"
          onClick={(i) => handleImage(index)}
          src={i}
          alt={"project.images + i"}
        />
      );
    });
  };

  const next = () => {
    let newIndex;
    if (idx === images.img.length - 1) {
      newIndex = 0;
    } else {
      newIndex = idx + 1;
    }
    setIdx(newIndex);
    setImage(images.img[newIndex]);
  };

  const previous = () => {
    let newIndex;
    if (idx === 0) {
      newIndex = images.img.length - 1;
    } else {
      newIndex = idx - 1;
    }
    setIdx(newIndex);
    setImage(images.img[newIndex]);
  };

  return (
    <>
      {open && (
        <div className="image-fs-container">
          <img src={image} alt={"project.images + i"} />
          <button onClick={next} className="right">
            <img src={arrowRight} alt="right-arrow" />
          </button>
          <button onClick={previous} className="left">
            <img src={arrowLeft} alt="left-arrow" />
          </button>
          <div onClick={() => setOpen(false)} className="image-background" />
        </div>
      )}
      <RenderImages />
    </>
  );
};

export default ImageViewer;
