import React from "react";
import { previsions } from "../text/previsions";
import previsionsBg from "../components/images/previsions.jpg";

const SummaryItem = ({ item }) => {
  return (
    <details>
      <summary>
        {item.year} - Ano de {item.title}
      </summary>
      <div className="previsions-info two-columns">
        <img alt="orixa-img" src={item.img} />
        {item.text}
      </div>
    </details>
  );
};

const Previsions = () => {
  return (
    <div className="previsions">
      <div className="hero-1">
        <img
          alt="previsions-banner"
          className="hero-cover"
          src={previsionsBg}
          style={{ translate: "0px 0px", transform: "scaleX(-1)" }}
        />
        <div style={{ position: "relative" }}>
          <h1>Previsões</h1>
        </div>
      </div>
      <div className="page-content">
        {previsions.map((i) => {
          return <SummaryItem item={i} />;
        })}
      </div>
    </div>
  );
};

export default Previsions;
