import React from "react";
import terreiro from "../components/images/terreiro.jpeg";
import { calendar } from "../text/calendar";
import logo from "../components/images/logo.png";

const getDay = () => {
  let allDays = [];
  calendar.forEach((i, idx) => {
    i.items.forEach((j) => {
      allDays.push({
        date: `${j.data.slice(0, 2)}/${idx}`,
        desc: j.desc,
      });
    });
  });
  let found = false;
  let day = 0;
  let month = 0;
  let response;

  allDays.forEach((i) => {
    if (found) {
      return;
    }
    const currentDate = new Date();
    const targetDate = new Date(
      2024,
      +i.date.slice(3, 5),
      +i.date.slice(0, 2) + 1
    );
    if (currentDate <= targetDate) {
      found = true;
      response = i.desc;
      day = i.date.slice(0, 2);
      month = +i.date.slice(3, 5) + 1;
      if (month.toString().length === 1) {
        month = `0${month}`;
      }
    }
  });

  return (
    <>
      Dia {day}/{month} <br />
      {response}
    </>
  );
};

const Home = () => {
  return (
    <div className="content">
      <div className="hero-1">
        <img className="hero-cover" alt="terreiro-banner" src={terreiro} />
        <div className="home-header">
          <img alt="logo" src={logo} />
          <div>
            <h1>Isto é Umbanda.</h1>
            <p>Mestre Marne, mestre de Umbanda e Cultos Afros.</p>
          </div>
        </div>
      </div>
      <div className="page-content">
        <br />
        <h2>Próximo evento</h2>
        {getDay()}
        <h2>2024 - Ano de Bará</h2>
        <h3>Previsões</h3>
        <p>
          A GRANDE RODA DA VIDA vai dar mais uma volta Na visão da Umbanda, em
          virtude o ano de 2.024 começar numa segunda feira será regido pelo
          ORIXÁ BARÁ. Os acontecimentos marcantes do ano são os seguintes.
          <br />
          <br />
          POSITIVOS: Tudo que tiver referência com a terra dará certo.
          Agricultura, Construção de Estradas, Pontes, Transportes Rodoviários,
          Será um ano de paixão (cuidado, pois paixão não é amor) dinheiro, A
          Economia Mundial dará um salto considerável. O espiritualismo
          continuará seu crescimento e a Cultura Africana mais reconhecida e
          respeitada em diversos aspectos da humanidade. <br />
          <br />
          NEGATIVOS: Acidentes Aéreos inexplicáveis. Pois o Bará puxa tudo pra
          Terra. Ao entrar num avião peça com fé a um ser divino que você tenha
          fé, para te proteger e a todos que ali estarão, pedindo que nada
          aconteça de ruim. Será um ano marcado por VINGANÇAS. Pois o orixá
          regente de 2024, não perdoa a maldade. O ser humano que vier a ser
          prejudicado por alguém, a primeira coisa que virá na sua cabeça é
          VINGANÇA. <br />
          <br /> No campo dos relacionamentos, haverá pouco amor, mas haverá
          avassaladora PAIXÃO. Ano propenso a muitas doenças, pois este orixá
          não entende nada de saúde. A doença principal será na cabeça. Como
          derrames cerebrais, Alzheimer e tumores cerebrais. Citamos isso, para
          que todos se cuidem, no excesso de cigarros, bebidas alcoólicas e
          principalmente qualquer tipo de droga alucinógena.
        </p>
        <h3>Ponto cantado - Bará Lodê 2024</h3>
        <div className="two-columns">
          <iframe
            title="mestre-marne-video"
            className="video"
            width="420"
            height="315"
            src="https://www.youtube.com/embed/5sxWTGmnu38"
          ></iframe>
          <p>
            ELE É BARÁ LODÊ yubára, yubára
            <br />
            O ELÊG VAMOS SAUDAR yubárayubára
            <br />
            SENHOR NA LUA E NO SOL yubára, yubára
            <br />
            SENHOR DA RUA É BARÁ yubára yubára. <br /> Bis todo o verso
            <br />
            <br />
            VAMOS SAUDAR O ELEGBÁ
            <br />
            ELEGBÁRA É BARÁ
            <br />
            O SEU PODER É ODÓLONÃ
            <br />
            LODÊ É LALUPONÃ
            <br />
            yubára, yubára. <br /> Bis todo o segundo verso.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
