import React from "react";
import Carta from "../components/carta_magna.pdf";

export const umbanda = [
  {
    title: "A Umbanda e Todos Nós",
    text: (
      <iframe
        className="video"
        width="420"
        height="315"
        title="A Umbanda e Todos Nós"
        src="https://www.youtube.com/embed/bnfHskOLJKI"
      ></iframe>
    ),
  },
  {
    title: "Carta Magna",
    text: (
      <>
        <a href={Carta} target="_blank" rel="noreferrer">
          Carta Magna em PDF
        </a>
        <br />
        <iframe
          className="video"
          width="420"
          height="315"
          title="A Umbanda e Todos Nós"
          src="https://www.youtube.com/embed/PRdVgzGz-vk"
        ></iframe>
      </>
    ),
  },
  {
    title: "O que é?",
    text: (
      <p>
        A Definição Clássica do que é Umbanda foi aprovada na II CONVENÇÃO
        NACIONAL DA UMBANDA, realizada pelo CONSELHO NACIONAL DELIBERATIVO DA
        UMBANDA E DOS CULTOS AFROS - CONDU/RJ, em 25/28 de agosto de 1.978, que
        é a seguinte: “UMBANDA é uma manifestação Divina, Culto de caráter
        Místico Religioso, projetado no Plano Astral do Brasil, com fundamento
        na Caridade.
      </p>
    ),
  },
  {
    title: "Significado",
    text: (
      <p>
        Em pesquisas realizadas por diversos escritores e estudiosos do assunto
        e também pelo CONDU/RJ, as versões variam muito, porque com esta grafia
        Umbanda, só foi encontrada no livro de registro, ou ata, da Federação
        Espírita de Niterói, porem na língua Bantu: AUM-BHAN-DHA, quer dizer:
        DEUS E TODOS NÓS, ou O ÚNICO POR TODOS NÓS. Pela grafia aum-bandha, o
        significado já é o limite do ilimitado e ainda o princípio divino. A
        UMBANDA passou a ser A Arte de Curar na gramática de Kimbundo, do
        professor José Luiz Quintão e KIMBANDA, quer dizer curandeiro na
        linguagem quimbundo, porém a grafia mais conhecida seria QUIMBANDA.
      </p>
    ),
  },
  {
    title: " Origem",
    text: (
      <p>
        Suas origens são remotas, vindo do berço de nossa civilização, talvez
        até de civilizações mais antigas, ainda desconhecida pelo homem. Porém a
        UMBANDA que praticamos agora, liberta de muitos de seus elos com outras
        religiões ou filosofias, livre de práticas e mitologias passadas,
        renasceu neste século, no dia l5 de novembro de 1908 no médium ZÉLIO
        FERNANDINO DE MORAES, em Niterói, então distrito do Rio de Janeiro.
        <br />
        <br />
        Acometido de uma doença que a medicina tradicional não conseguia
        debelar, Zélio foi levado a um centro de espiritismo da Linha de Alan
        Kardec. Lá, uma entidade espiritual diferente das que “baixavam” no
        local, manifestou-se em Zélio Fernandino de Moraes, identificando-se
        como índio e ao ser interpelado pelo médium vidente José de Souza, que
        lhe perguntou quem era ele, que respondeu: “Já que querem um nome, podem
        me chamar de Caboclo das Sete Encruzilhadas”. Disse que trazia uma
        missão de estabelecer normas de um culto, no qual os trabalhadores
        espirituais que se apresentavam sob a forma de índios e pretos escravos
        e que por isso eram repelidos nas sessões espíritas, viriam transmitir a
        Mensagem de Amor e Fraternidade, a Caridade Espiritual que se fazia
        necessária na época, quando só existiam as mesas kardecistas,
        elitizadas, altamente sofisticadas, sendo que os espíritos que chegavam
        eram somente de doutores e outros grandes mestres e a Macumba, com seus
        ritos grotescos, misteriosos e assustadores. <br />
        <br /> Esta Entidade Espiritual, Caboclo das Sete Encruzilhadas,
        pronunciou uma frase: “Levarei daqui uma semente e vou plantá-la nas
        Neves, onde ela se transformará em uma árvore frondosa e chamar-se-á
        UMBANDA”. No dia seguinte, l6 de novembro de 1908, na rua Floriano
        Peixoto, n. 30, no bairro Neves em Niterói/RJ, manifestou-se novamente
        aquele índio, que declarou fundado o Primeiro Templo dessa nova
        religião, que seria chamada de Tenda Nossa Senhora da Piedade, “da mesma
        forma que Maria ampara nos braços o Filho querido, também serão
        amparados os que se socorrerem da UMBANDA”. Explicou ainda porque seria
        Tenda: Porque lembra uma casa humilde. <br />
        <br /> Profetizou que esta nova religião iria se espalhar por todo o
        território brasileiro, sintetizando numa frase: “UMBANDA, é a
        MANIFESTAÇÃO DO ESPÍRITO PARA A CARIDADE”. Registre-se também, que neste
        mesmo dia, baixou o espírito de Preto Escravo que deu o nome de Pai
        Antônio ou Tio Antônio.
      </p>
    ),
  },
  {
    title: "Fundamentos",
    text: (
      <p>
        Os dez Fundamentos da Umbanda também foram aprovados na IIa. CONVENÇÃO
        NACIONAL DA UMBANDA. Onde um dos Convencionais discutiu veementemente,
        não aceitando o que estava sendo aprovado, pois ele estava lançando
        naquela mesma época um livro com os “fundamentos da Umbanda”, que na
        realidade não eram fundamentos e sim a própria prática, liturgia, a
        filosofia da religião. <br /> Assim sendo, meus amigos e meus irmãos, os
        Dez Fundamentos da Religião de Umbanda, são realmente estes:
        <ol>
          <li>A existência de um DEUS ÚNICO;</li>
          <li>Filosofia Religiosa Universal;</li>
          <li>A existência dos Orixás, entidades evoluídas;</li>
          <li>A Reencarnação;</li>
          <li>A Lei de Causa e Efeito;</li>
          <li>
            A existência de outras vidas, de outros Planos e de outras Linhas de
            Evolução;
          </li>
          <li>A natureza trina do HOMEM: Espírito, Alma e Corpo;</li>
          <li>A prática da Mediunidade;</li>
          <li>
            A manifestação dos Guias Espirituais, ainda em evolução nos Planos
            Intermediários e que são os mensageiros dos Orixás;
          </li>
          <li>
            A prática do BEM, através dessas manifestações. “Umbanda é a Missão
            do Bem!”
          </li>
        </ol>
      </p>
    ),
  },
  {
    title: "Divindades",
    text: (
      <p>
        O Deus da Religão Umbanda tem seus cultos próprios, cultuando a DEUS em
        primeiro lugar, que as denominações AUM - OBATALÁ - OLORUM e pelo seu
        início, 1908 a 1940 aproximadamente, também era conhecido por ZAMBI e
        TUPÃ. <br />
        Em seguida vem os Orixás, que são os Devas que comandam as Leis da
        Natureza:
        <ul>
          <li>
            OXALÁ: Regente da Paz, do Sorriso e da Tranquilidade. Responsável
            pela fertilidade do homem, em Cambinda é conhecido por Ganga Zumba,
            sincretiza com Jesus de Nazareth;
          </li>
          <li>
            YEMANJÁ: Mãe de todas as mães - Senhora das Águas - Rainha do Mar -
            Regente da Fertilidade da Mulher;
          </li>
          <li>
            OGUM: Senhor da Guerra, da Espada e do Aço - Defensor dos Oprimidos
            - Regente da fertilidade da terra, sincretiza com SÃO JORGE;
          </li>
          <li>
            OXOSSI: Senhor da Floresta - Rei das Matas - Caçador de Almas
            Perdidas - Dono da terapêutica do espaço - Curador de males físicos
            e espirituais através das plantas, raízes e folhas, sincretiza com
            SÃO SEBASTIÃO;
          </li>
          <li>
            XANGÔ: Senhor das Pedreiras - da Justiça - do Trovão e do Fogo - Rei
            de Oyó, sincretiza com SÃO JERÔNIMO;
          </li>
          <li>
            OXUM: Regente do Amor, da Bondade e do Ouro - Deusa das Águas doces:
            Rios, Lagos, Lagoas, Açudes - Deusa da beleza e da Riqueza,
            sincretiza com NOSSA SENHORA APARECIDA e NOSSA SENHORA DA CONCEIÇÃO;
          </li>
          <li>
            YANSÃ: Regente dos Ventos, das Chuvas, dos Raios, das Tempestades e
            Terremotos - Também conhecida por Oyá, sincretiza com SANTA BÁRBARA
            e SANTA JOANA D’ARC.
          </li>
        </ul>
      </p>
    ),
  },
  {
    title: "Doutrinas",
    text: (
      <p>
        Umbanda é uma religião Monoteísta, é Ciência e Filosofia, constituída de
        duas partes: Esotérica e Exotérica. <br /> A Esotérica é a parte oculta,
        destinada e reservada a quem se está preparando ou que já estiver em
        condições de receber a Luz da Iniciação. <br /> A exotérica, como
        Religião, destinada aos adeptos, segundo seu aperfeiçoamento,
        mentalidade e cultura. Uma congregação de Espíritos Evoluídos, nos
        níveis superiores do Plano Astral, formam a cúpula orientadora, que
        busca suavizar e contribuir para a evolução dos irmãos mais atrasados.
        <br /> Como religião apresenta-se de forma racional, de maneira que
        qualquer adepto, desde que raciocine, compreenda sua estrutura e suas
        finalidades, das quais as mais importantes são: A CARIDADE e a EVOLUÇÃO
        DO HOMEM. <br /> A finalidade da Umbanda, é prestar a caridade e
        auxiliar o Homem a encontrar o caminho de sua evolução. Ensinar a todos,
        que não se deve fazer o mal a quem quer que seja, pois a Lei de Retorno
        é infalível. Tudo que se fizer se receberá de volta. Portanto o melhor
        de tudo é fazer o BEM, sempre fazer o Bem, para receber o BEM. <br />
        Ensinar que os fatores “Sorte” ou “Azar” não existem. Na realidade são
        decorrências da própria ação do homem. Se a vida é boa, regular ou
        péssima, foi ele próprio que a preparou. Em cada encarnação, o ser
        humano prepara sua própria vida para as encarnações futuras. Por isso
        que a Filosofia Maior da Religião de Umbanda é: “O Homem é o Arquiteto
        de sua Própria Vida”. <br /> A liturgia de UMBANDA, seu ritual, é
        composto de Sessões de Cultos, cujas “giras” são realizadas no Templo.
        As Sessões dividem-se em: <br />
        A) - Sessões de Culto, Públicas - Chamadas de Sessões de Caridade ou
        Atendimento Público;
        <br /> B) - Sessões de Desenvolvimento Mediúnico e Aulas - Para o
        preparo dos iniciados e esclarecimentos aos adeptos, uma vez por semana
        (parte esotérica) <br />
        C) - Sessões de Vibrações Fluídicas - Para os médiuns, devem ser feitas
        uma vez por mês; <br />
        D) - Sessões Comemorativas: Nos dias consagrados aos Orixás Maiores,
        geralmente sincretizados com Santos Católicos; <br />
        E) - Sessões Esotéricas: Sessões de rituais de cruzamentos ou
        “acruzamentos”, que se imantam com os elementares da natureza. <br />
        F) - Sessões Especiais: Também chamadas de sessões de DEMANDA, ENGIRAS
        no máximo 7 vezes por ano. São sessões de descargas fluídicas, chamadas
        “fortes”, usando os Orixás, propriamente dito.
        <br /> Estes são os 7 tipos de Sessões que se realizam na UMBANDA.
        Alguns terreiros, separam o dia de Sessão de Desenvolvimento dos dias de
        Sessão de Aulas ou Doutrinas.
      </p>
    ),
  },
];
