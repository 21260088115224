import React from "react";
import { umbanda } from "../text/umbanda";
import umbandaBg from "../components/images/umbanda.jpg";

const SummaryItem = ({ item }) => {
  return (
    <details>
      <summary>{item.title}</summary>
      <p>{item.text}</p>
    </details>
  );
};

const Umbanda = () => {
  return (
    <div className="umbanda">
      <div className="hero-1">
        <img
          alt="umbanda-banner"
          className="hero-cover"
          src={umbandaBg}
          style={{ transform: "scaleX(-1)" }}
        />
        <div style={{ position: "relative" }}>
          <h1>Umbanda</h1>
        </div>
      </div>
      <div className="page-content">
        {umbanda.map((i) => {
          return <SummaryItem item={i} />;
        })}
      </div>
    </div>
  );
};

export default Umbanda;
