import React from "react";

export const Contact = () => {
  return (
    <div className="contact two-columns">
      <div>
        <p>Isto é Umbanda, por Mestre Marne</p>
        <p>Telefone: (47) 3366-4970</p>
        <p>Email: mestremarne@terra.com.br</p>
      </div>
      <div>
        <p>Reino de Juna Bomy</p>
        <p>Rua Dom Daniel, 200, (esquina C/ Apiuna)</p>
        <p> Bairro Vila Real - Balneário Camboriú - SC</p>
        <p> Fone +55 47 3361-6833</p>
      </div>
    </div>
  );
};
