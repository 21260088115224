import React, { useRef, useState } from "react";
import { infos } from "../text/infos";
import { orixas } from "../text/orixas";
import orixasBg from "../components/images/orixas.jpg";

const Infos = () => {
  const [selected, setSelected] = useState({
    title: infos[1].items[1].name,
    infos: "",
    text: infos[1].items[1].text,
    img: "",
  });
  const ref = useRef(null);

  const handleChange = (selected) => {
    setSelected({
      title: selected.name,
      text: selected.text,
      infos: selected.infos,
      img: selected.img,
    });
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="infos">
      <div className="hero-1">
        <img alt="orixas-banner" className="hero-cover" src={orixasBg} />
        <div style={{ position: "relative" }}>
          <h1>Orixás e Mediunidade</h1>
        </div>
      </div>
      <div className="page-content two-columns infos">
        <div>
          <details>
            <summary>Orixás</summary>
            <ul>
              {orixas.map((i) => {
                return <li onClick={() => handleChange(i)}>{i.name}</li>;
              })}
            </ul>
          </details>
          {infos.map((i) => {
            return (
              <details>
                <summary>{i.head}</summary>
                <ul>
                  {i.items.map((j) => {
                    return <li onClick={() => handleChange(j)}>{j.name}</li>;
                  })}
                </ul>
              </details>
            );
          })}
        </div>
        <div ref={ref}>
          <h1>{selected.title}</h1>
          {selected.img && (
            <div className="infos-header two-columns">
              <img
                alt="orixa-img"
                className="orixa-img"
                src={selected.img}
              ></img>

              {selected.infos}
            </div>
          )}

          {selected.text}
        </div>
      </div>
    </div>
  );
};

export default Infos;
