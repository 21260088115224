import React from "react";

export const infos = [
  {
    head: "Numerologia",
    items: [
      {
        name: "Numerologia dos Orixás",
        text: (
          <p>
            A pessoa que iniciou este estudo da numerologia dos orixás, tinha em
            mente de como acabar com as disparidades que existiam sobre que
            Santo seria o pai ou mãe de cabeça de tal filho ou filha. Pois
            estava cansado de verificar, dentro do seu próprio terreiro, que
            quando chegava um novo adepto, filho de santo ou médium de outro
            terreiro, era costume ouvir “você está com o santo de cabeça errado”
            ela ia o coitado mudar de santo novamente, sim, novamente por que
            ele também ouvia a mesma ladainha de sempre “pois quando eu cheguei
            lá naquele terreiro me disseram a mesma coisa”. Não era possível
            continuar assim, tinha que haver um meio em que todos tivessem algum
            “instrumento” em mãos como meio de saber com precisão a que santo
            pertencia tal filho ou filha. Isso aconteceu em 1968, na Bahia e a
            pedido do próprio Pai Homero, não identificaremos a que roça ou
            terreiro que ele pertencia, pois no inicio de seus estudos teve
            aborrecimentos com sua própria Mãe-de-Santo, que com o passar dos
            anos veio lhe dar razão passando a usar a Tábua Numerológica dos
            Orixás, não errando mais. Num passado não muito distante os pais e
            mães de santo usavam muito a ciência da frenologia, ou seja conforme
            era o rosto da pessoa, comparando lábios, testa, cabelos, tamanho do
            corpo, tez, olhos, era filho de tal Orixá, ouve tantos erros e
            alguns irreparáveis, que existem hoje muita gente fora da religião,
            pois tudo para eles deu errado. E tudo isso, por estar tratando o
            Orixá errado, como sendo seu. Saber qual o Orixá regente através dos
            búzios, até hoje dá errado. E porquê? Porque os búzios se manifestam
            através do Ifá, através da vibração do consulente, ele apanha tudo
            sobre o que está passando com aquela pessoa naquele instante, sendo
            que muitas vezes, aquela pessoa alguns minutos antes teve contato
            com outras pessoas, em outro ambiente e trouxe consigo “outra
            vibração” atrelada e os búzios vão acusar exatamente o que estiver
            acontecendo naquele momento. Muitos guias espirituais,
            principalmente dos Chefes de Terreiros, também cometem o mesmo erro,
            involuntariamente, pois dentro dos terreiros as manifestações e
            vibrações são múltiplas. Quantas vezes um médium novo, se aproxima
            do chefe do terreiro para saber qual é seu santo de cabeça e como
            exemplo citamos: sendo ele filho de Ogum, alguns minutos antes, foi
            cantado dentro do terreiro um mântra (ponto) para Xangô,
            evidentemente a vibração de Xangô está no cosmo e o Guia Espiritual,
            diz “você é filho de Xangô”. Erro involuntário. Neste instante nasce
            um médium novo, que já começa seu desenvolvimento, errado. A partir
            daí, além das chamadas mantrânicas que passam a serem feitas, para o
            desenvolvimento deste médium, Ele próprio vai fazer seus agrados e
            preces para Xangô, pensando ser filho de Xangô e não vai arrumar
            nada, pois Xangô não é seu pai de cabeça e sim Ogum, dentro do
            exemplo que apresentamos. Sabemos que vamos encontrar resistências
            por parte de alguns Diretores e Diretoras Espirituais (Pais e Mães
            de Santo), porém como já dissemos anteriormente, antes de passarem
            às críticas, façam experiências sobre tudo que vai ser registrado
            neste capítulo. Numerologia é matemática, e matemática é ciência
            exata. Ganhamos este estudo em 1.968, deixamos na gaveta por
            aproximadamente 20 (vinte) anos, sem dar importância e usando os
            métodos tradicionais que nos tinham ensinado. A consulta com o guia,
            que usava umas ponteiras de aço e por vibração intuitiva, dizia qual
            o pai ou mãe de cabeça daquela pessoa que estava em sua frente.
            Porém com o passar do tempo verificamos na prática o quanto tínhamos
            errado, mesmo sendo um percentual baixo de erros, ainda assim
            tínhamos a consciência que sem querer, prejudicamos alguns. Quando
            começou acontecer certos problemas com nossos próprios familiares e
            como sabíamos as características de cada Orixá e aqueles problemas
            não batia, nem com os defeitos e nem as virtudes do suposto pai de
            cabeça, acendeu em nós a curiosidade de pegar a Tábua Numerológica
            que estava esquecida na gaveta, e por incrível que possa parecer, ao
            compararmos a data do nascimento, repito, de nossos próprios
            familiares, verificávamos que o resultado final da soma dos números
            da data de nascimento acusava certo Orixá como pai o mãe de cabeça
            que não era o que tínhamos como certo e analisando os problemas por
            que estavam passando, fechava certíssimo com o Orixá apresentado
            pela numerologia. Desde então, nunca mais, nos atrevemos em dar uma
            Guia-Colar de cabeça para qualquer pessoas em consultar a Tábua
            Numerológica. Com a intenção primordial de acabar de uma vez por
            todas com estes erros que muitos Diretores Espirituais tem cometido,
            voluntária ou involuntariamente estamos entregando em suas mãos este
            trabalho, que temos a certeza vai revolucionar o método de saber
            finalmente quem é o pai de cabeça de alguém. Se todos vierem a se
            utilizar desta Tábua Numerológica, acabaremos com as divergências
            entre os terreiros sobre a história “você está com o Santo trocado”,
            o que aliás, vem causando um mal estar entre os Pais e Mães de
            Terreiro, porque sem dúvidas traz uma leve disputa entre eles. Se
            todos vierem a usar desta metodologia, que é ciência exata, não
            haverá mais divergências, todos estarão falando a mesma língua,
            evitando assim constrangimentos e aborrecimentos diversos. A nossa
            intenção é de que qualquer médium ou seguidor de Umbanda possa saber
            o Pai ou Mãe de cabeça de qualquer um, desde que, é lógico tenha em
            mãos a data de nascimento correta. Aliando a Tábua Numerológica, com
            os conhecimentos sobre os Orixás Maiores, suas características, suas
            virtudes e defeitos, saberemos com quem estamos lidando no nosso
            dia-a-dia. Este trabalho também auxiliará aos médiuns de todos os
            terreiros a detectarem as dificuldades que estiverem passando seus
            clientes e amigos, que muitas vezes não tem nada a ver com feitiços
            e trabalhos feitos e sim com as deficiências e fraquezas do próprio
            Anjo da guarda daquela pessoa. Todos os Orixás tem seu lado forte,
            como seu lado fraco. Por isso tem pessoas, que tem melhores
            condições, mas facilidades, de lidar com certo assunto, e outras até
            com maior estudo, encontram barreiras e enormes dificuldades no
            mesmo assunto. Ao saber qual é seu verdadeiro Orixá de Cabeça, você
            tem a RADIOGRAFIA DA ALMA, da PERSONALIDADE e do PRÉ-DESTINO das
            pessoas. A preocupação que devem ter aqueles que irão se utilizar
            deste trabalho é verificar se a data de nascimento está correta.
            Muitas pessoas, com uma idade avançada, sofreram no passado o velho
            problema de “nascer num dia e foi registrado em outro” o que vale
            mesmo, é a data real do nascimento, e não a que está no registro,
            inclusive os nascimentos por cesariana, mesmo que programada.
            Portanto, é só ter cuidado neste sentido que garantimos que tudo
            dará certo. Este estudo vem de pesquisas feitas em cima do que
            ensinaram: Os SUMÉRIOS, BABILÔNIOS e CALDEUS. Pela CULTURA GREGA,
            Século VI ªC. (Pitágoras); Do grande Psicólogo JUNG; Da TORÁ
            HEBRÁICA (Tarô); Estudos feitos em cima de “NO GEHEIME FIGUREN DER
            SOSENKREUTZER” ou seja OS SÍMBOLOS SECRETOS DOS ROSACRUZES dos
            Séculos XVI e XVII. Os Sistemas estudados foram portanto,
            Pitagórico, Hermético e Cabalístico. Uma das figuras mais
            extraordinárias da Grécia antiga não era nem sacerdote nem médium,
            mas um filósofo de nome Pitágoras, um homem que deve a fama de que
            atualmente goza ao teorema que tem o seu nome, usado para calcular
            comprimento da hipotenusa, ou lado maior, de um triângulo retângulo.
            No entanto Pitágoras era também um místico e um mágico, e como tal
            uniu a ciência e a magia de uma forma simultaneamente notável e
            enigmática. Nascido na ilha grega de Samos durante o século VI ªC,
            Pitágoras, segundo se crê, viajou muito, certamente até o Egito e
            provavelmente até ao Oriente. Finalmente, por volta de 530 ªC,
            fixou-se em Crotona, uma colônia grega na Itália Meridional, onde
            fundou uma sociedade secreta com o objetivo de expor as suas
            próprias filosofias. De acordo com as lendas que rodeiam a sua
            existência, Pitágoras possuía poderes extraordinários: conseguiu
            tornar-se invisível e caminhar sobre a água e tinha o poder de fazer
            aparecer e desaparecer os objetos, de acordo com a sua vontade.
            Acreditava também nas artes curativas através de músicas (neste
            livro, você encontrará no capítulo Mantrãns, maiores explicações
            sobre este assunto) e rituais específicos e afirmava que se
            recordava de anteriores formas encarnadas (no capítulo Reencarnação,
            falaremos mais sobre este assunto). No entanto, foi através dos
            números que Pitágoras procurou explicar o caos da existência humana,
            propondo uma ordem mais compreensível ainda do que a visionada pelos
            primeiros astrólogos. Na sua Metafísica, Aristóteles, que se opunha
            aos pitagóricos, tentou explicar a filosofia destes. Segundo os
            pitagóricos, todas as coisas são números, afirmou Aristóteles, de
            modo que “uma determinada combinação numérica representa e justiça,
            outra a alma e a razão, outra a oportunidade - e de modo semelhante
            quase tudo é numericamente exprimível”. Existe uma história que
            sugere que o interesse de Pitágoras pelos números derivava da sua
            descoberta de que as quatro principais notas da escala musical grega
            estavam inter-relacionadas. De acordo com a história, Pitágoras
            passando um dia pela loja de um ferreiro na altura em que quatro
            ferreiros batiam em quatro bigornas de tamanhos diferentes. Pesando
            as bigornas, Pitágoras verificou que os seus pesos estavam na
            proporção dos nºs 6, 8, 9 e 12. Subsequentemente, acabou por
            acreditar que semelhante inter-relação numérica se aplicava a toda a
            criação. Assim, de acordo com Aristóteles, “eles supunham que os
            elementos dos números eram os elementos de todas as coisas, e que
            todo o céu era uma escala musical e um número”. Mesmo que por
            séculos, estas crenças pareceram ingênuas, numa certa altura alguém
            ou alguns, começaram a dar crédito a Pitágoras, pois afinal ela era
            conhecedor profundo de magia, astrologia, matemática e música, que
            apresentou um sistema que propiciava ordem e poder adivinhatório. No
            âmago destes sistemas existia a crença de que todas as coisas tem
            números e que o número de uma coisa, tal como o seu nome, tinha
            significado especial ou mágico. Por exemplo, era possível determinar
            o número base do nome de uma pessoa e depois usá-lo para descrever o
            seu caráter e predizer acontecimentos futuros. Foi transposto tudo
            isso para a Tábua Numerólogica dos Orixás e como podem notar
            perfeitamente as relações científicas e religiosas de Pitágoras, que
            era um místico por excelência, se fecham com as dos Orixás,
            principalmente depois que alguém se dedicou durante anos a fio, com
            capacidade e inteligência nos doando esta maravilha que estamos
            repassando aos nossos leitores. Procuramos relatar, com maior
            preciosismo possível tudo sobre Pitágoras para que você tenha dados
            em mãos, da onde foi tirado a Numerologia dos Orixás, que sem
            dúvidas o estudo do saudoso Homero, foi calcado quase que todo em
            PITÁGORAS. Sobre o Sistema Hermético, mesmo tenhamos buscado
            incessantemente, conseguimos apenas o básico do que é Hermética:
            Obra atribuída a Hermes Trismesistrus, remonta, pelo menos, aos
            primeiros séculos da era cristã, contém símbolos e conceitos
            baseados nos números, tal como a dualidade do homem e do Cosmos, e
            uma tríade composta por DEUS, o COSMOS e o HOMEM. Cabala? É o
            misticismo judaico, base do SEPHER YEZIRATH, O LIVRO DA CRIAÇÃO, que
            traz suas correlações nos três Reinos: CÓSMICO, ASTRAL e HUMANO. O
            vocábulo Cabala, que em hebreu significa “as palavras recebidas” ou
            “sabedoria oculta”, define um corpo de tradição esotérica judaica
            que pretende apontar uma via para a compreensão de Deus e dos muitos
            mistérios do Universo. Sendo embora impossível datar com precisão as
            suas origens, crê-se que a Cabala, como uma forma de misticismo
            judaico, remonta pelo menos ao tempo de Cristo. Durante séculos uma
            tradição oral, a Cabala, na sua forma escrita, não é um texto único
            e integrado, mas uma coleção de escritos, geralmente complementares
            e ocasionalmente contraditórios, os mais importantes dos quais são:
            o já citado Livro da Criação, escrito entre os séculos II e VI d.C.,
            e o Livro do Esplendor, da autoria do espanhol Moisés de Leão, do
            século XIII. A principal crença da Cabala centra-se numa realidade
            oculta, à qual apenas transportes místicos e estudo ritualista
            facultam o acesso. Especificamente, a Cabala tenta reconciliar as
            aparentes contradições entre um Deus incognoscível e um Deus que se
            dá a conhecer; entre um Deus que é bom e criador de todas as coisas
            e um mundo onde prolifera o mal; entre um Deus infinito e eterno e
            um mundo - a sua criação - tão obviamente finito e condenado. O
            ponto fulcral da Cabala é um diagrama denominado a árvore da vida
            que consiste em 10 “emanações” de Deus e nas várias relações que
            existem entre elas. A Cabala inclui também uma ciência dos números
            chamada gematria, através da qual é possível realizar todas as
            interpretações arcanas da Escritura. Como podem ver, a Cabala
            contribui, e em muito, com estes estudos que foram repassados para a
            Numerologia dos Orixás. A Misteriosa Fraternidade Rosa-Cruz: As
            sociedades secretas tem constituído, desde tempos remotos, um dos
            elementos essenciais em que se concretiza o fascínio que a arte e o
            poder exercem sobre a Humanidade. Uma das mais misteriosas, e não
            obstante frequentemente mencionada é a Fraternidade Rosa-Cruz, Ordem
            Rosae Crucis, anunciada num pequeno panfleto publicado em Kassel,
            Alemanha, provavelmente em 1614. Intitulado Fama Fraternitatis, o
            folheto anônimo narrava a história de Christian Rosenkreuz, jovem
            piedoso e culto que, tendo viajado durante anos pelo Próximo
            Oriente, regressara à Alemanha como mestre em matemática e ciências
            naturais, bem como possuidor de conhecimentos ocultos. Reunindo à
            sua volta sete (7) discípulos, Rosenkreuz, segundo narra a história,
            supervisionou a compilação de uma vasta biblioteca, após o que cinco
            dos seus membros da irmandade percorreram o mundo a fim de realizar
            boas ações, comprometendo-se a reunirem-se anualmente, a encontrarem
            sucessores dignos e a manterem segredo durante 100 anos.
          </p>
        ),
      },
      {
        name: "Tábua Numerológica",
        text: (
          <div>
            <p>
              Para saber quem realmente é o seu Pai ou Sua Mãe de Cabeça, é
              necessário ter em mãos, com absoluta certeza a data de nascimento
              da pessoa. De posse destes dados, dia, mês e ano, fazer a soma
              individual de cada número. Exemplo: 13.05.1940, - somar então, da
              seguinte forma - 1 + 3 + 0 + 5 + 1 + 9 + 4 + 0 = 23, procurar na
              Tabela abaixo, verificaremos que a numerologia 23, para Homem é
              Filho de Ogum e para Mulher é filha de Yemanjá.
            </p>
            <div className="numberTable">
              <p>A Soma dos números da data de nascimento</p>
              <p>HOMENS</p>
              <p>MULHERES</p>
              <p>Até nº 20</p>
              <p>OXALÁ</p>
              <p>YEMANJÁ</p>
              <p>Com exceção de 7, 14 e 17</p>
              <p>OGUM</p>
              <p>YANSÃ</p>
              <p>Com exceção de 8</p>
              <p>XANGÔ</p>
              <p>OXUM</p>
              <p>De 21 a 29</p>
              <p>OGUM</p>
              <p>YEMANJÁ</p>
              <p>Com exceção de 26 e 28</p>
              <p>OXOSSI(Odé)</p>
              <p>YANSÃ</p>
              <p>
                A SOMA 28, Tanto Homem, como mulher, tem 3 Orixás, que respondem
                prontamente:
              </p>
              <p>OXOSSI, YANSÃ e YEMANJÁ</p>
              <p>YANSÃ, YEMANJÁ e OXOSSI</p>
              <p>
                PARA A MULHER 28 O ORIXÁ MAIOR só se define, após ter filhos:
                Sendo estéril ou até 2 filhos é ... Tendo 3 ou mais filhos é...
              </p>
              <p>...</p>
              <p>YANSÃ e YEMANJÁ</p>
              <p>A SOMA DOS NÚMEROS DANDO DE 30 A 33</p>
              <p>XANGÔ</p>
              <p>OXUM</p>
              <p>DE 34 ACIMA</p>
              <p>OXALÁ</p>
              <p>YANSÃ</p>
              <p>A mulher com a numerologia 26</p>
              <p>Deve evitar ter mais que</p>
              <p>Dois (2) Filhos</p>
            </div>
            <br />
            <p>
              Reafirmamos que quando todos estiverem usando esta Numerologia,
              estaremos evitando inúmeros aborrecimentos entre Diretores
              Espirituais (Pais e Mães de Terreiros) e até mesmo para os
              próprios médiuns iniciantes.
              <br />
              Ao tomarem conhecimento deste trabalho e a fazerem a sua própria
              numerologia , muitos constatarão de que “estão com a cabeça
              trocada”. Outros indignados dirão “mas faz tantos anos que
              trabalho com tal Orixá de cabeça, e agora me aparece essa”.
              <br />
              Queremos registrar o seguinte: O seu pai ou mãe de cabeça em
              muitos casos não tem nada a ver com o Guia Espiritual que você
              trabalha.
              <br />O que você tem que aprender é que seu guia espiritual, sua
              entidade espiritual, pode dar tudo para qualquer pessoa que tiver
              fé nele, porém pra você mesmo, somente seu Orixá de cabeça pode
              lhe atender.
              <br />
              Mesmo que sua entidade nunca lhe tenha dito isso, e eles não
              dizem, pois por incrível que possa parecer, sentem ciúmes de você,
              esta entidade sempre vai respeitar o que está em cima, ou seja o
              Orixá de cabeça.
              <br />
              Isto quer dizer que, somente o seu pai ou mãe de cabeça é quem
              pode lhe atender. Faça um retrospecto de sua vida, mas com
              sinceridade e veja o seguinte: sua entidade espiritual para os
              outros acerta tudo, ajuda em tudo, mas para você não.
              <br />
              Digo isso na hipótese de você estar trabalhando há anos com um
              tipo de vibração e agora ao verificar a tábua numerológica, notou
              que seu Orixá de Cabeça deu outro. Não há necessidade de se
              preocupar em trocar o seu guia, nada disso, continue trabalhando
              com ele, como sempre fez, porém ao pedir alguma coisa para si
              próprio, repito, peça para o Orixá de cabeça, que apresentou na
              numerologia. Faça esta experiência.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    head: "Mediunidade",
    items: [
      {
        name: "Incorporação",
        text: (
          <p>
            Mesmo que alguns terreiros, tenham em suas fileiras, médiuns
            videntes, clarividentes, intuitivos, o predominante mesmo é o de
            incorporação. Incorporação, são as vibrações de um espírito que atua
            de uma forma etérea sobre o corpo ou a mente de um médium, que
            necessariamente tem que ser portador de mediunidade de incorporação.
            O médium recebe em forma de vibrações energéticas, que conforme sua
            faculdade mediúnica pode manifestar-se pelo peito, pela nuca, pelo
            rosto, pela barriga, pelos braços, pelas pernas e pés. Podendo
            receber estas vibrações somente nas mãos, o que geralmente ocorre
            numa chamada da Linha de Oxalá. A incorporação então, são fluidos
            das entidades espirituais, uns com menor e outros com maior
            intensidade, dependendo do grau (tempo) de desenvolvimento
            mediúnico. Então, quando se diz que o médium tal, está incorporado
            com o caboclo tal, Orixá tal, estamos afirmando apenas uma meia
            verdade, pois na realidade ele está recebendo as ondas de freqüência
            energeticas da referida entidade espiritual. Uma maneira de explicar
            este fenômeno e afirmando; o sol está penetrando por uma janela
            aberta, sem cortinas, batendo num móvel, podendo causar algum efeito
            através de seus raios quentes sobre tal movel, é só uma maneira de
            dizer, pois na realidade não é o sol que está penetrando pela janela
            e sim, os raios solares.
          </p>
        ),
      },
      {
        name: "Mediunidade",
        text: (
          <p>
            Toda esta maravilha chamada Umbanda, está calcada na mediunidade de
            Incorporação dos seus seguidores. Mediunidade é capacidade do ser
            humano, chamada extrasensorial, onde se estabelece que não é só
            incorporação, o fenômeno mediúnico. Existem diversas formas de
            mediunidade, porém todas as demais cai no campo da paranormalidade,
            que já existe uma ciência chamada de Parapsicologia, que aliás,
            dizem foi criada pela Igreja Católica, ou pelo menos foi descoberta
            e aprimorada, mesmo que no início de “aplicação”, foi com a intenção
            de desmoralizar o espiritismo e o espiritualismo em geral. Porém os
            estudiosos e portadores de extraordinárias mediunidade souberam
            aproveitar e muito bem o evento, trazendo para dentro da Umbanda, os
            estudos feitos em nome da parapsicologia, conseguindo até
            desmistificar algumas fantasias que eram feitas em nome da Sagrada
            Religião. O que vem então, ao encontro do que já afirmamos em outro
            capítulo: Umbanda, além de religião, tem também ciência. Centenas ,
            ou até milhares de médiuns, participaram da “enxurrada” de cursos de
            parapsicologia, que no seu início, sempre tinha um padre da
            católica. Uns souberam e muito bem, aproveitar os ensinamentos
            recebidos, levando para dentro dos terreiros tudo que de bom
            aprenderam do campo da parapsicologia, transformando seus Templos em
            verdadeiras maravilhas, aumentando até a frequência de adeptos
            novos. O tiro então, saiu pela culatra. Se a ciência da
            paranormalidade foi criada para “destruir” o espiritualismo, muito
            pelo contrário, veio colaborar, e como, para o seu engrandecimento.
            Aprendemos que, medos, previsões, sensações de formigamento,
            pressentimentos, de sair e ver-se fora do corpo, sonhos que se
            realizam, claros ou confusos, percepção telepáticas, audição de
            “vozes” inexistentes, sons ruídos sem causa visível, sorte ou azar,
            são fenômenos paranormais. Tivemos explicações científicas para
            inúmeros fatos ou fenômenos que, por suas estranhas manifestações,
            eram atribuídos ao sobrenatural - forças estranhas vindas do além,
            de demônios, de espíritos maus, de almas penadas, de divindades
            iradas ou caprichosas, de Deus punidor. Fatos que derrubaram,
            centenas de Diretores Espirituais, pois pela falta de conhecimento,
            usavam nossos queridos guias, caboclos e pretos-velhos e até mesmo
            nossos Orixás, que por não serem para eles o “problema”, não
            solucionavam, porque não tinham o que solucionar, vinha o
            descrédito, a frustração, o desânimo, a falta de fé e
            consequentemente a derrota. Mediunidade de incorporação, não é
            fenômeno paranormal. É um dom Divino, que o Pe. Quevedo, o então
            papa da parapsicologia, coordenador do Centro Latino-Americano de
            Parapsicologia - CLAP - tenta até hoje a derrubar e não consegue. E
            não conseguirá nunca, por que é UM DOM DIVINO. E tudo que é Divino,
            ele mais que ninguém sabe: é indestrutível. A capacidade de receber
            um espírito é uma dádiva, porém tem suas preocupações. Um bom médium
            deve se preparar para receber um espírito. Um médium relapso, que
            não cuida da higiene do corpo, da higiene da mente, que pratica atos
            condenáveis na sua vida diária, logicamente, não receberá um bom
            espírito. Seu dom mediúnico de incorporação, está sendo mal usado.
            Está perdendo a grande oportunidade que Deus lhe concedeu. Porem, o
            ser humano consciente de que é portador deste dom Divino e que segue
            os ensinamentos de bons princípios e cuidados indispensáveis, com
            certeza, será um bom médium. Sem dúvidas nenhuma receberá bons
            espíritos. Espíritos iluminados, evoluídos, aquelas entidades que
            encantam os pacientes e engrandecem a Umbanda. Sua entidade
            espiritual, contribuirá com o que temos demais belo: a comunicação
            simples e direta com aquele irmão cheio de fé que vai em busca de um
            lenitivo para suas dores morais e físicas, que espontaneamente
            tratará de seus assuntos de uma forma clara e terá a possibilidade
            de resolver seus problemas a curto prazo, ganhando assim a Umbanda.
            A mediunidade de incorporação, além de um bom tempo de
            desenvolvimento, vai se consolidando através dos rituais
            sacramentais da religião, que são os “cruzamentos” ou “acruzamentos”
            com os elementares da natureza. Fortificando assim, seu campo
            vibratório, ganhando defesas contra os fluídos negativos que, sem
            dúvidas nenhuma, muitos pacientes irão trazer para este médium. A
            mediunidade para ser cada vez maior e mais segura, sua prática é o
            melhor burilamento. Mediunidade é tão Divina, quem vem desde o
            ventre da mãe e enquanto ela não for desenvolvida e praticada, nossa
            vida fica sem defesas e tudo isso é em virtude da reencarnação. É
            uma missão que trazemos que tem que ser cumprida, pois muitas vezes,
            chega num terreiro um problema que somente poderá ser resolvido pela
            nossa mediunidade, não estando alí, ficaremos devendo e
            consequentemente nossas mazelas aumentarão. Pode parecer cruel por
            parte dos Orixás de que, não assumindo a missão da mediunidade,
            nossa vida não tem sucesso e tudo para nós fica mais difícil, porém
            está aí o âmago da questão. Algumas pessoas, nossos irmãos,
            dependerão de nós para se curarem ou resolverem seus problemas.
            Enquanto não assumimos, estamos protelando a felicidade de alguém e
            por isso não merecemos ser feliz, também. E tem mais, após assumir a
            missão pré-destinada, não nos é permitido em faltar as sessões,
            principalmente as de atendimento público, pois a qualquer momento em
            qualquer dia, estará alí aquela pessoa que vai depender
            exclusivamente de nós para solucionar seus problemas e não estando
            nós alí.. bem, ficamos devendo e o que é pior, desta vez consciente
            de nossos deveres para com a nossa mediunidade e com a nossa missão.
            Finalizando, lembramos que cada médium tem a sua própria experiência
            ou seu grau mediúnico que o diferencia dos demais. Ele é chamado na
            Umbanda de Cavalo e de Aparelho, por ceder seu corpo para a
            incorporação dos guias e entidades espirituais e deve ser
            responsável ao máximo, seguindo rigorosamente os preceitos de seu
            Pai-de-Terreiro ou Diretor Espiritual e da própria UMBANDA.
          </p>
        ),
      },
    ],
  },
];
