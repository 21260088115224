import React from "react";
import terreiro from "../components/images/terreiro.jpeg";
import { calendar } from "../text/calendar";

const Calendar = () => {
  return (
    <div className="photos">
      <div className="hero-1">
        <img alt="terreiro-banner" src={terreiro} className="hero-cover" />
        <div style={{ position: "relative" }}>
          <h1>Programação 2024</h1>
        </div>
      </div>
      <div className="page-content calendar">
        {calendar.map((i, idx) => {
          return (
            <details>
              <summary>{i.month}</summary>
              <div>
                {i.items.map((j, index) => {
                  return (
                    <>
                      <span className="date">{j.data}</span>
                      {j.desc}
                    </>
                  );
                })}
              </div>
            </details>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
