import React, { useState } from "react";

const Meet = () => {
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("m");
  const [result, setResult] = useState("");
  const [visible, setVisible] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const formattedDate = formatBirthdate(value);
    setBirthdate(formattedDate);
  };

  const formatBirthdate = (input) => {
    const numericValue = input.replace(/\D/g, "");
    const match = numericValue.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);

    if (match) {
      let formattedDate = "";
      if (match[1]) {
        formattedDate += match[1];
      }
      if (match[2]) {
        formattedDate += `/${match[2]}`;
      }
      if (match[3]) {
        formattedDate += `/${match[3]}`;
      }
      return formattedDate;
    }
    return input;
  };

  const checkGender = (a, b) => {
    if (gender === "m") {
      setResult(a);
    } else {
      setResult(b);
    }
  };

  const calculate = () => {
    if (birthdate === "" || birthdate.length < 10) return;
    if (birthdate[0] > "3" || (birthdate[0] === "3" && birthdate[1] > "1")) {
      alert("Dia inválido");
      return;
    }
    if (birthdate[3] > 1 || (birthdate[3] === "1" && birthdate[4] > "2")) {
      alert("Mês inválido");
      return;
    }
    if (
      birthdate[6] > "2" ||
      (birthdate[6] === "2" && birthdate[7] !== "0") ||
      (birthdate[6] === "2" && birthdate[8] >= "3") //QUEM FOR MEXER NESSE SITE, ISSO AQUI QUE LIMITA SÓ ATÉ 2030
    ) {
      alert("Ano inválido");
      return;
    }
    const sum = birthdate
      .split("/")
      .join("")
      .match(/\d/g)
      .reduce((acc, curr) => acc + parseInt(curr, 10), 0);

    if (sum === 8 || (sum >= 30 && sum <= 33)) {
      checkGender("Xangô", "Oxum");
    } else if (sum > 33) {
      checkGender("Oxalá", "Yansã");
    } else if (sum === 28) {
      setResult("Oxossi, Yansã e Yemanjá");
    } else if (sum === 7 || sum === 14 || sum === 17) {
      checkGender("Ogum", "Yansã");
    } else if (sum <= 20) {
      checkGender("Oxalá", "Yemanjá");
    } else if (sum === 26 || sum === 28) {
      checkGender("Oxossi(Odé)", "Yansã");
    } else if (sum >= 21 && sum <= 29) {
      checkGender("Ogum", "Yemanjá");
    }

    setVisible(true);
  };

  return (
    <div>
      <label>Data de nascimento: </label>
      <input
        type="text"
        id="birthdate"
        name="birthdate"
        value={birthdate}
        placeholder="Somente números"
        onChange={handleInputChange}
        maxLength={10}
      />
      <br />
      <br />
      <label>Seu gênero: </label>
      <select onChange={(e) => setGender(e.target.value)}>
        <option value="m">Masculino</option>
        <option value="f">Feminino</option>
      </select>
      <br />
      <br />

      <button onClick={calculate}>Calcular</button>
      <br />
      <br />
      {visible && <h2>Você é filho(a) de {result}</h2>}
    </div>
  );
};

export default Meet;
