import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import { Contact } from "./components/Contact";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/scrollToTop";
import Calendar from "./pages/Calendar";
import Home from "./pages/Home";
import Infos from "./pages/Infos";
import Photos from "./pages/Photos";
import Previsions from "./pages/Previsions";
import Umbanda from "./pages/Umbanda";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Navbar />
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route exact path="/fotos" Component={Photos} />
          <Route exact path="/umbanda" Component={Umbanda} />
          <Route exact path="/infos" Component={Infos} />
          <Route exact path="/previsoes" Component={Previsions} />
          <Route exact path="/programacao" Component={Calendar} />
        </Routes>
        <Contact />
      </div>
    </Router>
  );
};

export default App;
