export const PhotosList = [
  {
    title: "Festa de Ogum 2015",
    photos: require.context(`../components/images/photos/ogum2015`),
  },
  {
    title: "Festa de Ogum 2016",
    photos: require.context(`../components/images/photos/ogum2016`),
  },
  {
    title: "Festa de Ogum 2018",
    photos: require.context(`../components/images/photos/ogum2018`),
  },
  {
    title: "Festa de Oxum 2018",
    photos: require.context(`../components/images/photos/oxum2018`),
  },
  {
    title: "Festa de Iansã 2018",
    photos: require.context(`../components/images/photos/iansa2018`),
  },
  {
    title: "Festa de Ogum 2019",
    photos: require.context(`../components/images/photos/ogum2019`),
  },
  {
    title: "Festa de Iansã 2022",
    photos: require.context(`../components/images/photos/iansa2022`),
  },
  {
    title: "Festa de Iansã 2023",
    photos: require.context(`../components/images/photos/iansa2023`),
  },
  {
    title: "Festa de Iemanjá 2023",
    photos: require.context(`../components/images/photos/iemanja2023`),
  },
  {
    title: "Festa de Ogum 2023",
    photos: require.context(`../components/images/photos/ogum2023`),
  },
  {
    title: "Festa de Oxum 2023",
    photos: require.context(`../components/images/photos/oxum2023`),
  },
];
