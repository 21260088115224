import React from "react";
import terreiro from "../components/images/terreiro.jpeg";
import ImageViewer from "../components/imageViewer";
import { PhotosList } from "../text/photos";

const Photos = () => {
  return (
    <div className="photos">
      <div className="hero-1">
        <img alt="terreiro-banner" className="hero-cover" src={terreiro} />
        <div style={{ position: "relative" }}>
          <h1>Fotos</h1>
        </div>
      </div>
      <div className="page-content">
        {PhotosList.map((i, idx) => {
          const srcList = i.photos.keys().map((image) => i.photos(image));
          return (
            <details key={idx}>
              <summary>{i.title}</summary>
              <div className="photos-container">
                {/* {srcList.map((j, index) => (
                  <img key={index} src={j} alt={`${i.title}-${index}`} />
                ))} */}
                <ImageViewer img={srcList} />
              </div>
              <br />
              <br />
            </details>
          );
        })}
      </div>
    </div>
  );
};

export default Photos;
