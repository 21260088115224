import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-items">
        <Link to="/">Home</Link>
        <Link to="/umbanda">Umbanda</Link>
        <Link to="/infos">Orixás e Mediunidade</Link>
        <Link to="/previsoes">Previsões</Link>
        <Link to="/fotos">Fotos</Link>
        <Link to="/programacao">Programação</Link>
      </div>
    </div>
  );
};

export default Navbar;
